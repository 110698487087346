<template>
  <CRow class="mb-4">
    <CCol v-if="advanced">
      <label v-translate translate-context="form_configurator.text.label">Field Key</label>
      <CInput v-model="key" @input="save" :placeholder="keyPlaceholder"/>
    </CCol>
    <CCol>
      <label v-translate translate-context="form_configurator.text.label">Field name</label>
      <PTranslatableTextInput v-model="name" @input="save" :langs="langs"/>
    </CCol>
  </CRow>
</template>

<script>
import PTranslatableTextInput from "@/domain/core/components/PTranslatableTextInput.vue";
import {generateKey} from "@/domain/formConfigurator/fields";

export default {
  name: "FormTextInput",
  components: {PTranslatableTextInput},
  props: {
    data: {
      type: Object,
      required: true
    },
    advanced: {
      type: Boolean,
      required: true
    },
    langs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      key: '',
      keyPlaceholder: '',
      name: {}
    }
  },
  emits: ['update:data'],
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.key = this.data.key ?? ''
      this.name = this.data.name ?? {}
      this.keyPlaceholder = generateKey(this.name)
    },
    save() {
      this.keyPlaceholder = generateKey(this.name)
      this.$emit('update:data', {
        ...this.data,
        key: this.key,
        name: this.name
      })
    }
  }
}
</script>