<template>
  <CModal :show.sync="show" centered size="lg">
    <div class="grid-stripped" v-if="release">
      <PFRow :label="$gettext('Gallery / Price List')" size="3x9">
        <template v-if="hasPerm('pica_code.set_gallery')">
          <div class="d-flex">
            <CSelect v-model="gallery_id" :options="galleryWithNotSet" class="mr-2"/>
            <CButton color="primary" size="sm" @click="onSetGallery">
              Set
            </CButton>
            <CButton color="primary" size="sm" @click="onCreateGallery">
              +
            </CButton>
          </div>
        </template>
        <template v-else>
          {{ release.gallery.name }}
        </template>
      </PFRow>
      <PFRow :label="$gettext('Codec')" size="3x9">
        <template v-if="hasPerm('pica_code.set_codec')">
          <div class="d-flex">
            <CSelect v-model="codec_id" :options="codecsWithDefault" class="mr-1"/>
            <CButton color="primary" size="sm" @click="onSetCodec">
              Set
            </CButton>
          </div>
        </template>
        <template v-else>
          <template v-if="release.codec">
            {{ release.codec.label }}
          </template>
          <template v-else>
            Default - {{ codec.label }}
          </template>
        </template>

      </PFRow>
      <PFRow :label="$gettext('Created By')" size="3x9">
        {{ release.created_by }}
      </PFRow>
      <PFRow :label="$gettext('Created At')" size="3x9">
        {{ release.created_at | formatDateTime }}
      </PFRow>
    </div>
    <template #footer>
      <CButton color="secondary" size="sm" @click="show = false">Close</CButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";

export default {
  name: "ReleaseDetailModal",
  components: {PFRow},
  computed: {
    ...mapGetters('picaCode', [
      'release',
      'galleries'
    ]),
    ...mapGetters('core', ['codecs']),
    ...mapGetters('event', ['codec']),
    codecsWithDefault() {
      return [
        {label: `Default - ${this.codec.label}`, value: ''},
        ...this.codecs,
      ]
    },
    galleryWithNotSet() {
      return [
        {label: 'Not Set', value: null},
        ...this.galleries
      ]
    }
  },
  emits: ['update'],
  data() {
    return {
      show: false,
      gallery_id: null,
      codec_id: null,
    }
  },
  methods: {
    ...mapActions('picaCode', [
      'fetchRelease',
      'fetchGalleries',
      'setGallery',
      'setCodec'
    ]),
    ...mapActions('core', ['fetchCodecs']),
    async open(id) {
      this.show = true
      await this.fetchRelease(id)
      this.gallery_id = this.release.gallery?.id
      this.codec_id = this.release.codec?.id

      if (this.hasPerm('pica_code.set_gallery'))
        await this.fetchGalleries()
      if (this.hasPerm('pica_code.set_codec'))
        await this.fetchCodecs()
    },
    async onSetGallery() {
      await this.setGallery(this.gallery_id)
      this.$emit('update')
    },
    onSetCodec() {
      this.setCodec(this.codec_id)
    },
    onCreateGallery() {
      const parent = this.getParentWithMethod('openCreateGallery')
      if (parent) parent.openCreateGallery({release: this.release})
      else this.notifyError({text: 'Parent not found'})
    }, async refreshGallery() {
      await this.fetchRelease(this.release.id)
      this.gallery_id = this.release.gallery?.id
    }
  }
}
</script>
