import {translate} from 'vue-gettext'

const {pgettext: $pgettext} = translate

export const tooltipColor = '#A9A8C2'
export const tooltipContent = {
    // Activation
    activation: $pgettext('stats.tooltip', 'Number of event participants who accessed their album.'),
    activationRate: $pgettext('stats.tooltip', 'Percentage of activations over estimated participants.'),
    activationTotal: $pgettext('stats.tooltip', 'Total activations broken down by channel used to access the album.'),
    activationByGroup: $pgettext('stats.tooltip', 'Number of people (faces) submitted into a single album by a user to initiate facial recognition. Photos associated with the submitted faces are automatically added to the album.'),
    activationByContent: $pgettext('stats.tooltip', 'Breakdown of activations by range of photos delivered to albums. The percentage is calculated in relation to total activations.'),

    tags: $pgettext('stats.tooltip', 'Breakdown of activations by tracking tag.'),

    // Engagement
    contentPromotersPercentage: $pgettext('stats.tooltip', 'Percentage of users with at least one photo available who performed downloading or social network sharing actions.'),
    contentPromoters: $pgettext('stats.tooltip', 'Users with at least one photo available who performed downloading or social network sharing actions.'),
    sharedMedia: $pgettext('stats.tooltip', 'Total amount of downloads and/or shares of media, by channel.'),
    socialViews: $pgettext('stats.tooltip', 'Number of views generated by the shared content. Percentage is calculated with respect to social network channels only, excluding downloads.'),
    shareMultiplier: $pgettext('stats.tooltip', 'Indicator of the popularity of shared media, given as the ratio of social views to shared media.'),

    // Photographic production
    deliveredPhotos: $pgettext('stats.tooltip', 'Total number of photos delivered to activations. A delivered photo is counted each time a user portrayed in an image logs in. The same photo may be delivered to multiple users.'),


}