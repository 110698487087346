<template>
  <div>
    <transition name="fade" v-if="loading">
      <div class="page-loader text-center">
        <CSpinner color="primary" size="lg" />
      </div>
    </transition>
    <slot />
  </div>
</template>

<script>
export default {
    name: "PTransparentLoader",
    props: {
      loading: {
        type: Boolean,
        default: false
      },
    }
}
</script>
