<template>
  <PComponentLoader :loading="loading">
    <CCard class="note-card">
      <CCardHeader class="py-2">
        <h5 class="m-0" v-translate>Notes</h5>

        <div class="card-header-actions">

          <CButton color="dark" class="btn-circle" variant="outline"
                   @click="addNewNote=!addNewNote">
            <CIcon :name="addNewNote ? 'cipMinus':'cipPlus'" class="text-primary"/>
          </CButton>
        </div>
      </CCardHeader>

      <CCardBody class="px-0 pt-0">
        <transition name="slide-note">
          <EventNoteForm class="px-4" v-if="addNewNote" @save="onCreateNote"/>
        </transition>
        <CListGroup flush class="list-striped" v-if="eventNote && eventNote.length > 0">
          <CListGroupItem class="flex-column align-items-start mb-0 border-bottom-0"
                          v-for="(item, index) in eventNote" :key="index">
            <div class="font-weight-semi-bold mb-2" style="font-size: 13px">
              {{ createdBy(item.created_by) }} - {{ item.created_at|formatDateTime }}
            </div>
            <template v-if="editNoteId === item.id">
              <transition name="slide-note">
                <EventNoteForm @save="onUpdateNote" :text="item.text"/>
                <CButton @click="editNoteId = null" color="danger">
                  <translate>Cancel</translate>
                </CButton>
              </transition>
            </template>
            <template v-else>
              <div v-html="item.text"></div>
              <div class="d-flex justify-content-end mt-2 pt-3 border-top">
                <CButton @click="deleteEventNote(item.id)" color="danger" class="mr-2">
                  <translate>Delete</translate>
                </CButton>
                <CButton @click="editNoteId = item.id" color="primary">
                  <translate>Edit</translate>
                </CButton>

              </div>
            </template>
          </CListGroupItem>
        </CListGroup>
        <div class="py-4" style="padding-left: 18px" v-else>
          <translate>No notes</translate>
        </div>
      </CCardBody>
    </CCard>
  </PComponentLoader>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import EventNoteForm from "@/domain/eventWizard/components/overview/EventNoteForm.vue";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";

export default {
  name: 'EventNote',
  components: {
    PComponentLoader,
    EventNoteForm
  },
  emits: ['create', 'update', 'delete'],
  data() {
    return {
      addNewNote: false,
      editNoteId: null,
      loading: true,
    }
  },
  async mounted() {
    this.loading = true
    await this.fetchEventNote()
    this.loading = false
  },
  computed: {
    ...mapGetters('eventWizard', [
      'eventNote'
    ]),
  },
  methods: {
    ...mapActions('eventWizard', [
      'fetchEventNote',
      'createNewEventNote',
      'deleteEventNote',
      'updateEventNote'
    ]),
    createdBy(item) {
      return item.first_name && item.last_name ? `${item.first_name[0]}.  ${item.last_name}` : item.email
    },
    async onCreateNote(note) {
      await this.createNewEventNote(note)
      await this.notifyMessage({
        text: this.$gettext('Note saved'),
        messageType: 'success'
      })
      this.addNewNote = false
    },
    async onUpdateNote(text) {
      await this.updateEventNote({
        text: text,
        id: this.editNoteId
      })
      await this.notifyMessage({
        text: this.$gettext('Note updated'),
        messageType: 'success'
      })
      await this.emitPromised('update',)
      this.editNoteId = null
    },
  }
}
</script>
