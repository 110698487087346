<template>
  <CCol md="6" xl="4" class="mb-3" v-if="activeGalleries.length > 0">
    <div class="overview-widget galleries" data-v-step="0">
      <div class="overview-widget__head">
        <div class="d-flex align-items-center">
          <h5 class="m-0" v-translate>Upload and deliver photos</h5>
          <PTooltip class="ml-2" :content="$pgettext('overview.tooltip', 'Select the folder and upload all the photos of the event.')" :color="tooltipColor" placement="top"/>
        </div>
      </div>
      <div class="overview-widget__img">
        <CImg :src="require('@/assets/images/overview/upl.png')" fluid block class="w-100"/>
      </div>
      <div class="overview-widget__body">
        <label class="small" v-translate translate-context="event_wizard.overview">Choose a gallery</label>
        <GalleriesDropDown :galleries="activeGalleries" @click="routeToGallery"/>
      </div>
    </div>
  </CCol>
</template>

<script>
import {mapGetters} from "vuex";
import PTooltip from "@/domain/core/components/PTooltip.vue";
import {tooltipColor} from "@/domain/eventWizard/components/overview/tooltip";
import GalleriesDropDown from "@/domain/eventWizard/components/overview/GalleriesDropDown.vue";

export default {
  name: "GalleriesUpload",
  components: {GalleriesDropDown, PTooltip},
  computed: {
    ...mapGetters('event', [
      'picaServiceId',
    ]),
    ...mapGetters('eventGallery', [
      'galleries',
    ]),
    tab() {
      let route = this.$route.matched.find(m => m.props?.default?.tab)
      if (route) return route.props.default.tab
      return "detail"
    },
    tooltipColor() {
      return tooltipColor
    },
    activeGalleries() {
      return this.galleries.map(gallery => {
        const sub = gallery.sub.filter(sub => !sub.passive)
        if (sub.length === 0 && gallery.passive) return null
        return {
          ...gallery,
          sub: sub
        }
      }).filter(gallery => gallery !== null)
    }
  },
  methods: {
    routeToGallery(gallery) {
      this.$router.push({name: `eventWizard.gallery.${this.tab}`, params: {picaServiceId: this.picaServiceId, galleryId: gallery.id}})
    }
  }
}
</script>


<style scoped>

</style>