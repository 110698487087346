<template>
  <PTransparentLoader :loading="loading" class="event-wizard" v-if="loaded">
    <CContainer class="p-0">
      <h1 class="main-header mb-1" v-translate translate-context="event_wizard">Data collection</h1>
      <p v-translate translate-context="event_wizard.subtitle">Create a data collection form to obtain key information
        from event participants</p>
      <hr class="mt-4 mb-4">
      <CRow class="mb-4 mt-4" v-if="isFormSaved">
        <CCol lg="12" class="text-right">
          <CButton color="success" @click="$emit('downloadResults')" v-translate class="mb-3"
                   translate-context="event_wizard">Download results
          </CButton>
        </CCol>
      </CRow>
      <PFRow size="5x7" :label="$pgettext('form_configurator.label', 'Title')"
             :help-text="$pgettext('form_configurator.help', 'Insert the form title')"
             separator
             label-class="big">
        <PTranslatableTextInput
            v-model="form.title"
            :placeholder="$pgettext('form_configurator.placeholder','eg: Stay updated on news')"
            :langs="langs"
            @keyup="previewFieldUpdated"
        />
      </PFRow>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Logo')"
             :help-text="$pgettext('form_configurator.logo_help','A logo adds a personal touch to your form, creating a more engaging and authentic experience for other participants')"
             separator label-class="big">
        <PUploader
            :params="uploadParams"
            :get_intent="get_logo_upload_intent"
            :value.sync="form.logo"
            :preview="logo_url"
            :help_text="$pgettext('form_configurator.logo_help','Allowed formats: jpg, jpeg, png')"
            @uploaded="newLogoUploaded"
        />
      </PFRow>

      <template v-if="advanced">
        <PFRow size="5x7" :label="$pgettext('form_configurator.label', 'Primary Color')" separator
               label-class="big">
          <PColorPicker :color="form.style.primary_color" disable-alpha @change="onChangePrimaryColor"/>
        </PFRow>

        <PFRow size="5x7" :label="$pgettext('form_configurator.label', 'Background Color')" separator
               label-class="big">
          <PColorPicker :color="form.style.bg_color" disable-alpha @change="onChangeBgColor"/>
        </PFRow>

        <PFRow size="5x7" :label="$pgettext('form_configurator.label', 'Edge curvature')" separator
               label-class="big">
          <div class="btn-group-squared btn-group-toggle">
            <label class="btn btn-outline-secondary btn-toggle" v-for="(radius, index) in elementRadiusOptions"
                   :key="index"
                   :class="{'active': radius.value === form.style.input_radius}">&nbsp;
              <input type="radio" name="form-radius" :id="`option-${index}`"
                     v-model="form.style.input_radius"
                     :value="radius.value"
                     :checked="radius.value === form.style.input_radius"
                     @change="previewFieldUpdated">
              <CIcon size="lg" :name="radius.iconName"/>
            </label>
          </div>
        </PFRow>

        <PFRow size="5x7" :label="$pgettext('form_configurator.label', 'Actions')"
               :help-text="$pgettext('form_configurator.subtitle', 'Choose from various options to interact with the form')"
               separator
               label-class="big">
          <div class="form-check form-check-inline" v-for="(option, index) in skipOptions" :key="index">
            <label class="form-check-label d-flex flex-column align-items-center cursor-pointer mr-3">
              <span class="mb-3">
               <template v-if="option.input">
                <img :src="require(`@/assets/images/event_wizard/step_form/${option.input}.png`)" style="width: 190px">
              </template>
              <template v-else>
                <span class="d-block" style="padding: 40px 0">{{ option.label }}</span>
              </template>
              </span>
              <input type="radio" name="skip-option" :id="`option-${index}`"
                     class="form-check-input"
                     v-model="form.skip_mode"
                     :value="option.input"
                     :checked="option.input === form.skip_mode"
                     @change="previewFieldUpdated">


            </label>
          </div>
        </PFRow>

      </template>
      <PFRow size="5x7" :label="$pgettext('form_configurator.label', 'Fields')"
             :help-text="$pgettext('form_configurator.help', 'Select the data or information you want to ask the user before they access the photos')"
             separator label-class="big">
        <Fields
            :data.sync="form.fields"
            :advanced="advanced"
            :langs="langs"
            @preview="previewFieldUpdated"
        />
      </PFRow>
      <PFRow size="5x7" :label="$pgettext('form_configurator.label', 'Privacy and Marketing')"
             :help-text="$pgettext('form_configurator.help', 'Activate privacy and marketing consents')"
             separator
             label-class="big">
        <Consents
            :data.sync="form.consents"
            :get_consent_upload_intent="get_consent_upload_intent"
            :advanced="advanced"
            :langs="langs"
            @preview="previewFieldUpdated"
        />
      </PFRow>
    </CContainer>

    <div class="event-wizard__footer">
      <CRow>
        <CCol>
          <div class="d-flex justify-content-end">
            <div class="mr-2">
              <CButton color="secondary" block @click="onReset" ref="reset-button"
                       v-translate translate-context="event_wizard">
                Reset
              </CButton>
            </div>
            <div>
              <CButton color="primary" @click="onSave" block ref="save-button"
                       v-translate translate-context="event_wizard">
                Save
              </CButton>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
  </PTransparentLoader>
</template>

<script>
import PUploader from "@/domain/core/components/PUploader.vue";
import Consents from "@/domain/formConfigurator/component/Consents.vue";
import Fields from "@/domain/formConfigurator/component/Fields.vue";
import PTranslatableTextInput from "@/domain/core/components/PTranslatableTextInput.vue";

import {
  defaultConsents,
  defaultFields,
  generateKey,
  generateRandomId,
  skipOptions,
  elementRadiusOptions,
} from "@/domain/formConfigurator/fields";
import {DELETE_KEY} from "@/domain/core/components/uploader";
import {cloneDeep} from "lodash";
import PColorPicker from "@/domain/core/components/PColorPicker.vue";
import PTransparentLoader from "@/domain/core/components/PTransparentLoader.vue";
import PFRow from "@/domain/core/components/PFRow.vue";

const defaultForm = {
  title: {},
  skip_mode: '',
  logo: '',
  preview_logo: '',
  style: {
    bg_color: '#FFFFFF',
    primary_color: '#1867FF',
    input_radius: 0,
  },
  fields: [...defaultFields],
  consents: [...defaultConsents],
}


export default {
  name: "StepForm",
  components: {PFRow, PTransparentLoader, PColorPicker, PTranslatableTextInput, Fields, Consents, PUploader},
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    get_consent_upload_intent: {
      type: Function,
      required: true
    },
    get_form_s3_public_url: {
      type: Function,
      required: true
    },
    get_logo_upload_intent: {
      type: Function,
      required: true
    },
    get_upload_intent_public_url: {
      type: Function,
      required: true,
    },
    isPublished: {
      type: Boolean,
      required: true
    },
    isPicaManager: {
      type: Boolean,
      required: true
    },
    advanced: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      logo_url: null,
      loaded: false,
      showFieldEditor: false,
      fieldEdited: {},
      form: {},
    }
  },
  computed: {
    langs() {
      return this.advanced ? process.env.VUE_APP_SUPPORTED_LANGS.split(',') : ['it']
    },
    skipOptions() {
      return skipOptions
    },
    elementRadiusOptions() {
      return elementRadiusOptions
    },
    uploadParams() {
      return {
        allowed_extensions: ['jpg', 'jpeg', 'png'],
        compression: {minWidth: 600, height: 600},
        input_image_size: {minWidth: 300, minHeight: 300, aspectRatio: 1},
      }
    },
    isFormSaved() {
      return 'id' in this.data.form
    },
  },
  emits: ['save', 'updatePreview', 'downloadResults'],

  mounted() {
    this.onReset()
  },
  methods: {
    onChangeBgColor(color) {
      this.form.style.bg_color = color.hex
      this.previewFieldUpdated()
    },
    onChangePrimaryColor(color) {
      this.form.style.primary_color = color.hex
      this.previewFieldUpdated()
    },

    async onReset() {
      if (!this.data.form || !this.data.form.fields)
        this.data.form = defaultForm

      const data = this.data.form
      this.logo_url = await this.get_form_s3_public_url(data.logo)
      if (!data.fields) data.fields = []
      if (!data.consents) data.consents = []

      this.form = {
        title: {...data.title},
        style: {...data.style},
        skip_mode: data.skip_mode,
        logo: data.logo,
        fields: data.fields.map(field => {
          return {
            id: generateRandomId(),
            type: field.type,
            order: field.order,
            mandatory: field.mandatory,
            editable: this.isFieldEditable(field),
            deletable: this.isFieldDeletable(field),
            key: field.key,
            name: field.name,
            params: field.params ?? {}
          }
        }),
        consents: data.consents.map(consent => {
          return {
            order: consent.order,
            id: generateRandomId(),
            mandatory: consent.mandatory,
            editable: this.isConsentEditable(consent),
            deletable: this.isConsentDeletable(consent),
            key: consent.key,
            text: consent.text,
            uploads: [],
          }
        }),
      }

      await this.previewFieldUpdated()
      this.loaded = true
    },
    async onSave() {
      await this.emitPromised('save', {form: this.getMappedForm()})
      await this.onReset()
    },
    async previewFieldUpdated() {
      this.$emit('updatePreview', await this.getPreviewForm())
    },
    getMappedForm() {
      return {
        title: {...this.form.title},
        style: {...this.form.style},
        skip_mode: this.form.skip_mode,
        logo: this.form.logo,
        fields: this.form.fields.map(field => {
          if (!field.key || field.key.length <= 0)
            field.key = generateKey(field.name)
          return {
            type: field.type,
            order: field.order,
            mandatory: field.mandatory,
            key: field.key,
            name: field.name,
            params: field.params ?? {}
          }
        }),
        consents: this.form.consents.map(consent => {
          return {
            order: consent.order,
            key: consent.key,
            mandatory: consent.mandatory,
            uploads: consent.uploads.filter(upload => upload.intent).map(upload => upload.intent),
            text: consent.text ?? {}
          }
        }),
      }
    },
    async getPreviewForm() {
      const previewForm = cloneDeep(this.getMappedForm())
      previewForm.logo = this.logo_url
      previewForm.consents = await Promise.all(previewForm.consents.map(async consent => {
        for (const lang in consent.text) {
          consent.text[lang] = await this.replaceS3Link(consent.text[lang])
          consent.text[lang] = await this.replaceIntentLink(consent.text[lang])
        }
        return consent
      }))
      return previewForm
    },

    async replaceS3Link(text) {
      const promises = [];
      const regex = /{{S3_FILE:(.*?)}}/g;
      text.replace(regex, (match, file) => {
        const promise = this.get_form_s3_public_url(file);
        promises.push(promise);
      });
      const data = await Promise.all(promises);
      return text.replace(regex, () => data.shift());
    },
    async replaceIntentLink(text) {
      const promises = [];
      const regex = /{{INTENT:(.*?)}}/g;
      text.replace(regex, (match, file) => {
        const promise = this.get_upload_intent_public_url(file)
        promises.push(promise);
      });
      const data = await Promise.all(promises);
      return text.replace(regex, () => data.shift());
    },

    isFieldEditable(field) {
      if (this.isPicaManager) return true // Pica operator can always edit fields
      if (this.isPublished) return false // If it's published, we don't want to have mismatching fields in the export
      return !(field.type === 'email' && field.name.it === 'Email'); // Email field is never editable
    },
    isFieldDeletable(field) {
      if (this.isPicaManager) return true // Pica operator can always edit fields
      return !(field.type === 'email' && field.name.it === 'Email'); // Email field is never editable
    },
    // eslint-disable-next-line no-unused-vars
    isConsentEditable(consent) {
      if (this.isPicaManager) return true // Pica operator can always edit fields
      if (this.isPublished) return false // If it's published, we don't want to have mismatching fields in the export
      return true
    },
    // eslint-disable-next-line no-unused-vars
    isConsentDeletable(consent) {
      if (this.isPicaManager) return true // Pica operator can always edit fields
      return true
    }, async newLogoUploaded() {
      await this.$nextTick()
      if (this.form.logo === DELETE_KEY)
        this.logo_url = ''
      else {
        this.logo_url = await this.get_upload_intent_public_url(this.form.logo)
      }
      this.previewFieldUpdated()
    },
  }
}
</script>

<style scoped>

</style>