<template>
  <PTransparentLoader :loading="loading" class="event-wizard">
    <CContainer class="p-0">
      <h1 class="main-header mb-1" v-translate translate-context="event_wizard">E-Mail Scheduler</h1>
      <CRow class="">
        <CCol>
          <h5 class="mb-1" v-translate translate-context="event_wizard.title">Scheduled Email</h5>
          <p v-translate translate-context="event_wizard.subtitle">
            Create one or more e-mail schedule for the photographer manager</p>
          <CLink class="form-generator__add-field" @click="addSchedule">
            <CIcon class="text-primary mr-1" size="xl" name="cipPlusCircle"/>
            <span v-translate>Add schedule</span>
          </CLink>
        </CCol>
      </CRow>
      <div class="form-generator__field" v-for="schedule in scheduler" :key="schedule.id">
        <div class="flex-grow-1 mr-4" @click="editSchedule(schedule)">
          <EmailSchedule :data="schedule"/>
        </div>
        <div class="mr-3">
          <CButtonClose class="list-item-closer" @click="deleteSchedule(schedule)">
            <CIcon name="cipClose" size="custom-size" width="15"/>
          </CButtonClose>
        </div>
      </div>
    </CContainer>

    <div class="event-wizard__footer fluid">
      <CRow>
        <CCol>
          <div class="d-flex justify-content-end">
            <div class="mr-2">
              <CButton color="secondary" block @click="onReset" ref="reset-button"
                       v-translate translate-context="event_wizard">
                Reset
              </CButton>
            </div>
            <div>
              <CButton color="primary" @click="onSave" block :disabled="v$.$invalid" ref="save-button"
                       v-translate translate-context="event_wizard">
                Save
              </CButton>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>

    <EditEmailSchedule
        v-if="showEditor"
        :data="schedule"
        @save="saveSchedule"
        @cancel="showEditor = false"
    />
  </PTransparentLoader>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import PTransparentLoader from "@/domain/core/components/PTransparentLoader.vue";
import {v4 as uuid} from "uuid";
import EmailSchedule from "@/domain/eventWizard/components/EmailSchedule.vue";
import EditEmailSchedule from "@/domain/eventWizard/components/EditEmailSchedule.vue";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: "StepEmailScheduler",
  components: {EditEmailSchedule, EmailSchedule, PTransparentLoader},
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      scheduler: [],
      schedule: null,
      showEditor: false,
    }
  },
  emits: ['save'],
  setup() {
    return {v$: useVuelidate()}
  },
  mounted() {
    this.showEditor = false
    this.onReset()
  },
  computed: {
    ...mapGetters('event', ['timeZone']),
  },
  methods: {
    onReset() {
      if (!this.data.scheduler) this.data.scheduler = []
      this.scheduler = this.data.scheduler.map(schedule => {
        return {
          ...schedule,
          schedule_from: moment.tz(schedule.schedule_from, this.timeZone),
          schedule_to: moment.tz(schedule.schedule_to, this.timeZone),
        }
      })
    },

    async onSave() {
      await this.emitPromised('save', {
        scheduler: this.scheduler.map(schedule => {
          // The id is valid and should be kept only if it's a "valid" id, and not a "fake" uuid used only for differentiate element in the loop
          return {
            ...schedule,
            id: schedule.isNew ? null : schedule.id,
            schedule_from: moment.tz(schedule.schedule_from, this.timeZone).startOf('day'),
            schedule_to: moment.tz(schedule.schedule_to, this.timeZone).endOf('day')
          }
        })
      })
      this.onReset()
    },

    addSchedule() {
      this.schedule = {
        id: uuid(),
        schedule_from: null,
        schedule_to: null,
        send_time: null,
        isNew: true
      }
      this.showEditor = true
    },
    saveSchedule(schedule) {
      let found = false
      this.scheduler = this.scheduler.map(s => {
        if (s.id === schedule.id) {
          found = true
          return schedule
        }
        return s
      })
      if (!found)
        this.scheduler.push(schedule)
    },
    editSchedule(schedule) {
      this.showEditor = true
      this.schedule = schedule
    },
    deleteSchedule(schedule) {
      this.scheduler = this.scheduler.filter(s => s.id !== schedule.id)
    },
  }
}
</script>

<style scoped>

</style>