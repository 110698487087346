<template>
  <PIframe :src="frontEndUrl" :route="frontEndRoute" :data="data"/>
</template>

<script>

import PIframe from "@/domain/core/components/PIframe.vue";

export default {
  name: "PreviewCheckinOptions",
  computed: {
    frontEndUrl() {
      // TODO: Parametrizzare per domini terzo livello
      return process.env.VUE_APP_PICA_WEBSITE_FRONTEND_IFRAME
    },
    frontEndRoute() {
      return {page: 'preview.checkin_options'}
    },
  },
  components: {PIframe},
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>