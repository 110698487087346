<template>
  <CMedia class="labels-layout-item" :class="{'labels-layout-item--selected': selected}"
          :aside-image-props="{ height: 120, width: 200 }"
          add-aside-classes="aside-picacode-wrapper">
    <template #aside>
      <img :src="item.sample_image" alt="" height="100px">
    </template>
    <h6 class="mt-2">{{ item.name }}</h6>
    <div v-html="item.description"></div>
    <div class="text-right p-3">
      <CButton v-if="selected" class="labels-layout-item__button" color="primary" disabled>
        <translate>Selected</translate>
      </CButton>
      <CButton v-else
               class="labels-layout-item__button"
               color="primary" variant="outline"
               @click="$emit('select')">
        <translate>Select</translate>
      </CButton>
    </div>
  </CMedia>
</template>

<script>
export default {
  name: "LabelLayoutItem",
  emits: ['select'],
  props: {
    item: Object,
    selected: Boolean
  },
}
</script>
<style lang="scss">
.labels-layout-item {
  position: relative;
  margin-bottom: 1em;
  border-radius: 4px;
  border: 1px solid #d8dbe0;
  padding: 1rem;
}

.labels-layout-item--selected {
  border: 1px solid #1472f6;
}

.labels-layout-item__button {
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.aside-picacode-wrapper {
  background-color: #2e3037;
  width: 150px;
  text-align: center;
  border-radius: 4px;
}
</style>