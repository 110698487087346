<template>
  <CModal :show.sync="show" size="lg" centered :title="$pgettext('event_wizard.tag_editor.title', 'Allowed tags')">
    <div>
      <table class="table table-tag-edit">
        <tr>
          <th>Category</th>
          <th>Tags</th>
          <th></th>
        </tr>
        <tr v-for="(category, index) in categories" :key="index">
          <template v-if="category.implicit">
            <td>
              {{ category.long_name }}
            </td>
            <td>
              <CBadge v-for="tag in category.tags" :key="tag" shape="pill" color="primary-light no-delete mr-2 mb-2">
                {{ tag }}
              </CBadge>
            </td>
            <td></td>
          </template>
          <template v-else>
            <td>
              <CInput v-model="category.name" placeholder="Category Name"/>
            </td>
            <td>
              <PTagInput
                  v-model="category.tags"
                  addable
                  :placeholder="$pgettext('event_wizard.tag_editor.placeholder','Tags')"
              />
            </td>
            <td>
              <CButton @click="deleteCategory(index)" color="danger" v-translate v-if="!category.system">
                Remove
              </CButton>
            </td>
          </template>
        </tr>
        <tr>
          <td colspan="2">
            <CButton color="primary" @click="addCategory">Add</CButton>
          </td>
        </tr>
      </table>
    </div>
    <template #footer>
      <CButton @click="show = false" color="secondary" v-translate>Cancel</CButton>
      <CButton @click="onSave"
               color="primary"
               translate-context="event_wizard.tag_editor.button" v-translate>
        Save
      </CButton>
    </template>
  </CModal>
</template>

<script>

import PTagInput from "@/domain/core/components/PTagInput.vue";
import tagTypes from "@/domain/core/constant/tagTypes";

export default {
  name: "TagEditor",
  components: {PTagInput},
  data() {
    return {
      categories: [
        {
          name: '',
          tags: []
        }
      ],
      show: false
    }
  },
  emits: ['save'],
  methods: {
    open(tags) {
      const implicitCategory = tagTypes.options().map(type => ({
        name: type.id,
        long_name: type.name,
        tags: [],
        implicit: true,
      }))

      this.categories = Object.keys(tags).reduce((categories, category) => {
        const existingCategory = categories.find(cat => cat.name === category)
        if (existingCategory) {
          existingCategory.tags = tags[category]
        } else {
          categories.push({
            name: category,
            tags: tags[category]
          })
        }
        return categories
      }, implicitCategory)
      this.show = true
    },
    addCategory() {
      this.categories.push({
        name: '',
        tags: []
      })
    },
    deleteCategory(index) {
      this.categories.splice(index, 1)
    },
    async onSave() {
      this.$emit('save', this.categories.reduce((acc, category) => {
        if (category.name.length > 0 && category.tags.length > 0)
          acc[category.name] = category.tags
        return acc
      }, {}))
      this.show = false
    },
  }
}
</script>

<style scoped>

</style>