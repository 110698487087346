export const downloader = {
    fromSvg(svgHtmlElement, fileName) {
        const svgData = svgHtmlElement.outerHTML
        const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
        const svgUrl = URL.createObjectURL(svgBlob)
        this.fromObjectUrl(svgUrl, fileName)
    },
    fromJpegCanvas(canvasElement, fileName, options = {padding: 0, backgroundColor: '#ffffff'}) {
        const { padding, backgroundColor } = options
        const paddedCanvas = this.addPaddingToCanvas(canvasElement, padding, backgroundColor);
        const canvasData = paddedCanvas.toDataURL("image/jpeg")
        this.fromObjectUrl(canvasData, fileName);
    },
    fromPngCanvas(canvasElement, fileName, options = {padding: 0, backgroundColor: '#ffffff'}) {
        const { padding, backgroundColor } = options
        const paddedCanvas = this.addPaddingToCanvas(canvasElement, padding, backgroundColor);
        const canvasData = paddedCanvas.toDataURL("image/png")
        this.fromObjectUrl(canvasData, fileName)
    },
    fromObjectUrl(url, fileName) {
        const downloadLink = document.createElement("a")
        downloadLink.href = url
        downloadLink.download = fileName
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    },
    addPaddingToCanvas(canvasElement, padding, backgroundColor) {
        const originalWidth = canvasElement.width
        const originalHeight = canvasElement.height
        const paddedWidth = originalWidth + padding * 2
        const paddedHeight = originalHeight + padding * 2

        const paddedCanvas = document.createElement('canvas')
        paddedCanvas.width = paddedWidth
        paddedCanvas.height = paddedHeight
        const ctx = paddedCanvas.getContext('2d')

        // Fill the background with white color (optional)
        ctx.fillStyle = backgroundColor
        ctx.fillRect(0, 0, paddedWidth, paddedHeight)

        // Draw the original canvas onto the padded canvas
        ctx.drawImage(canvasElement, padding, padding)

        return paddedCanvas
    }
}