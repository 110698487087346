<template>
  <div class="event-wizard">
      <CContainer class="p-0">
        <h1 class="main-header mb-1" v-translate translate-context="event_wizard">Event Profile</h1>
        <p>
          <translate translate-context="event_wizard.label">Personalize with the main information of your event
          </translate>
        </p>
        <PFRow size="5x7" :label="$pgettext('event_wizard.label','Event name') + '*'"
               :help-text="$pgettext('event_wizard.help','Insert the event name')"
               separator
               label-class="big">
          <CInput
              v-model="v$.form.name.$model"
              ref="form-name"
              :is-valid="!v$.form.name.$error"
              :maxlength="v$.form.name.maxLength.$params.max"
              addWrapperClasses="has-counter"
              @keyup="previewFieldUpdated();askForAvailableWelcomeCode()"
              :placeholder="$pgettext('event_wizard.placeholder','Event name')">
            <template v-slot:description>
              <div class="counter" v-if="!v$.form.name.$error">{{
                  v$.form.name.$model ? v$.form.name.$model.length : 0
                }}/{{ v$.form.name.maxLength.$params.max }}
              </div>
            </template>
            <template v-slot:invalid-feedback>
              <div class="invalid-feedback d-block" v-for="error of v$.form.name.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </template>
          </CInput>
        </PFRow>

        <PFRow size="5x7" :label="$pgettext('event_wizard.label','Subtitle')+'*'"
               :help-text="$pgettext('event_wizard.help','Add subtitle in the appropriate fields for a complete description')"
               separator
               label-class="big">
          <CFormGroup>
            <template v-slot:input>
              <PEmoticonInput
                  v-model="v$.form.subtitle.$model"
                  ref="form-subtitle"
                  @keyup="previewFieldUpdated"
                  :maxlength="v$.form.subtitle.maxLength.$params.max"
                  :is-valid="!v$.form.subtitle.$error"
                  :errors="v$.form.subtitle.$errors"
                  @update:value.native="previewFieldUpdated"
                  :placeholder="$pgettext('event_wizard.placeholder','Subtitle 1')">
              </PEmoticonInput>
            </template>
          </CFormGroup>
        </PFRow>

        <PFRow size="5x7" :label="$pgettext('event_wizard.label','Date and time zone') + '*'"
               :help-text="$pgettext('event_wizard.help','Enter the event start date and select the time zone')"
               separator
               label-class="big">
          <CRow form>
            <CCol md="6">
              <template v-if="canChangeDates">
                <template v-if="date_type === dateTypes.single_day || !date_type">
                  <VueCtkDateTimePicker
                      :value="form.start_at"
                      :no-label="true"
                      :no-button="true"
                      :auto-close="true"
                      :label="$gettext('Select date')"
                      format="YYYY-MM-DD HH:mm:ss"
                      formatted="LL"
                      :only-date="true"
                      :no-button-now="true"
                      color="#1867FF"
                      :class="['custom-picker', {'is-invalid': v$.form.start_at.$error }]"
                      @input="onSetDate"
                  />
                </template>
                <template v-if="date_type === dateTypes.multi_day">
                  <VueCtkDateTimePicker
                      :value="dateValues"
                      :no-label="true"
                      :label="$gettext('Select date')"
                      format="YYYY-MM-DD HH:mm:ss"
                      formatted="LL"
                      range
                      :only-date="true"
                      :no-button-now="true"
                      color="#1867FF"
                      @input="onSetDate"
                  />
                </template>
                <template v-if="date_type === dateTypes.multi_day_with_time">
                  <VueCtkDateTimePicker
                      :value="form.start_at"
                      :no-label="true"
                      :label="$gettext('Select dates')"
                      format="YYYY-MM-DD  HH:mm:ss"
                      formatted="LLL"
                      :no-button-now="true"
                      color="#1867FF"
                      @input="onSetDate($event,'start_at')"
                  />
                  <VueCtkDateTimePicker
                      :value="form.end_at"
                      :no-label="true"
                      :label="$gettext('Select date')"
                      format="YYYY-MM-DD  HH:mm:ss"
                      formatted="LLL"
                      :no-button-now="true"
                      color="#1867FF"
                      @input="onSetDate($event,'end_at')"
                  />
                </template>
                <div class="invalid-feedback d-block" v-if="v$.form.start_at.$error"
                     v-translate translate-context="event_wizard.error">Required field
                </div>
              </template>
              <template v-else>
                <div class="font-weight-medium">{{ form.start_at | formatDate('LL') }}</div>
              </template>
            </CCol>
            <CCol md="6">
              <template v-if="canChangeDates">
                <TimezoneSelect v-model="v$.form.timezone.$model"/>
              </template>
              <template v-else>
                <div class="font-weight-medium">{{ form.timezone }}</div>
              </template>
            </CCol>
          </CRow>
        </PFRow>
        <PFRow size="5x7" :label="$pgettext('event_wizard.label','Profile photo')"
               :help-text="$pgettext('event_wizard.help','A profile photo adds a personal touch to your event, creating a more engaging and authentic experience for other attendees')"
               label-class="big"
               separator
               v-if="enable_logo">
          <PUploader
              :params="uploadParams.logo"
              :get_intent="get_logo_upload_intent"
              :value.sync="v$.form.logo.$model"
              use_cropper
              :cropper_aspect_ratio="1"
              @uploaded="newLogoUploaded"
          />
          <p class="text-help mt-1">
            <translate translate-context="event_wizard.help">
              The image should be square, at least 200x200px - Allowed formats: .jpeg - .png
            </translate>
          </p>
          <div class="invalid-feedback d-block" v-if="v$.form.logo.$error" v-translate
               translate-context="event_wizard.error">Logo field has an error.
          </div>
        </PFRow>
        <PFRow size="5x7" :label="$pgettext('event_wizard.label','Estimated participants')"
               label-class="big" separator v-if="hasPerm('event_wizard.participants_count')">
          <CInput :value.sync="v$.form.participants_count.$model"/>
        </PFRow>
        <PFRow size="5x7" :label="$pgettext('event_wizard.label','Currency')"
               :help-text="$pgettext('event_wizard.help','Select the currency in which the event will be managed')"
               v-if="!promo"
               separator
               label-class="big">
          <template v-if="published">
            <div class="font-weight-medium">{{ form.currency }}</div>
          </template>
          <template v-else>
            <CurrencySelect v-model="v$.form.currency.$model"/>
          </template>
        </PFRow>
        <template v-if="showWelcomeCode">
          <PFRow size="5x7" :label="$pgettext('event_wizard.label','Welcome code')"
                 label-class="big"
                 separator
                 v-if="enable_welcome_code && form.name && form.start_at">
            <template #title>
              <div class="d-flex align-items-center justify-content-between">
                <label class="big" v-translate translate-context="event_wizard">Welcome code</label>
                <div class="d-flex align-items-center" v-if="allows_custom_welcome_code">
                  <label class="m-0" v-translate translate-context="event_wizard">Custom</label>
                  <CSwitch class="mx-2 mt-2"
                           color="primary"
                           variant="3d" size="sm"
                           :checked.sync="welcome_codes.manual"
                           @update:checked="welcome_codes.input = form.welcome_code"
                  />
                </div>
              </div>
            </template>
            <template #help-text>
              <p class="text-help-dark" v-translate translate-context="event_wizard">
                Select a welcome code of 9 characters, it will be used by the user to access your album
              </p>
              <PAlert
                  :text="$pgettext('event_wizard', 'The welcome code should not be changed after the event is published')"
                  class="mb-2"
                  color="danger"
                  v-if="published && hasPerm('advanced.change_welcome_code_after_publish')"/>
              <PAlert
                  :text="$pgettext('event_wizard', 'The welcome code cannot be changed after the event is published')"
                  class="mb-2"
                  color="info"
                  v-else/>
            </template>
            <template v-if="welcome_codes.manual">
              <p v-translate translate-context="event_wizard">
                Type a custom welcome code, must be 9 characters long
              </p>
              <CInput
                  @change="onChangeCustomWelcomeCode"
                  v-model="welcome_codes.input"
                  :placeholder="$pgettext('event_wizard.placeholder','Welcome Code')"
              >
                <template v-slot:invalid-feedback>
                  <div class="invalid-feedback d-block" v-if="welcome_codes.error">
                    {{ welcome_codes.error }}
                  </div>
                </template>
                <template v-slot:append-content>
                  <CSpinner size="sm" v-if="welcome_codes.loading"/>
                  <CIcon name="cipCheck" size="sm" width="36" height="36" class="mr-3y"
                         v-else-if="welcome_codes.input.length && welcome_codes.input === form.welcome_code"/>
                </template>
              </CInput>


            </template>
            <template v-else>
              <p v-translate translate-context="event_wizard">
                Select a welcome code of 9 characters, it will be used by the user to access your album
              </p>
              <multiselect
                  class="mb-2"
                  :options="welcome_codes_options"
                  v-model="v$.form.welcome_code.$model"
                  ref="form-welcome-code"
                  :searchable="false"
                  @select="previewFieldUpdated"
                  :placeholder="$pgettext('event_wizard.placeholder','Pica Code')"
                  :allow-empty="false"
              />
            </template>
            <div class="invalid-feedback d-block" v-if="v$.form.welcome_code.$error" v-translate
                 translate-context="event_wizard.error">Pica code field has an error.
            </div>
          </PFRow>
        </template>
      </CContainer>


    <div class="event-wizard__footer">
      <CRow>
        <CCol>
          <div class="d-flex justify-content-end">
            <!--            <div class="mr-2">
                          <CButton color="secondary" size="sm" block @click="onReset" ref="reset-button"
                                   v-translate translate-context="event_wizard">
                            Reset
                          </CButton>
                        </div>-->
            <div>
              <PButton color="primary" @click="onSave" block :disabled="!isFormChanged"
                       ref="save-button"
                       :loading="loading"
                       v-translate translate-context="event_wizard">
                Save
              </PButton>
            </div>

          </div>
        </CCol>
      </CRow>
    </div>
  </div>


</template>

<script>
import {useVuelidate} from '@vuelidate/core'
import {maxLength, minLength, requiredIf} from '@vuelidate/validators'
import TimezoneSelect from "@/domain/core/components/TimezoneSelect.vue";
import PUploader from "@/domain/core/components/PUploader.vue";
import Multiselect from "vue-multiselect";
import {upload} from "@/domain/eventWizard/upload";
import {DELETE_KEY} from "@/domain/core/components/uploader";
import {ValidationError} from "@/domain/core/exception/exceptions";
import PEmoticonInput from "@/domain/core/components/PEmoticonInput.vue";
import dateTypes from "@/domain/core/constant/dateTypes";
import CurrencySelect from "@/domain/core/components/CurrencySelect.vue";
import PFRow from "@/domain/core/components/PFRow.vue";
import {mapActions} from "vuex";
import PAlert from "@/components/PAlert.vue";
import {minLength$, required$} from "@/domain/core/utils/validators";
import PButton from "@/components/PButton.vue";

export default {
  name: "StepDateAndName",
  computed: {
    dateTypes() {
      return dateTypes
    },
    dateValues() {
      if (this.date_type === dateTypes.multi_day)
        return {start: this.form.start_at, end: this.form.end_at}
      else if (this.date_type === dateTypes.multi_day_with_time)
        return {start: this.form.start_at, end: this.form.end_at}
      return this.form.start_at
    },
    uploadParams() {
      return upload
    },
    welcome_codes_options() {
      let codes = this.welcome_codes.fetched
      if (this.welcome_codes.original) {
        if (!this.welcome_codes.fetched.includes(this.welcome_codes.original))
          codes = [this.welcome_codes.original, ...codes]
      }
      return codes
    },
    showWelcomeCode() {
      if (!this.form.name) return false
      if (!this.form.start_at) return false
      if (!this.enable_welcome_code) return false
      if (!this.published) return true
      return this.hasPerm('advanced.change_welcome_code_after_publish')
    },
    canChangeDates() {
      if (!this.published) return true
      if (this.published && this.hasPerm('advanced.change_date_after_publish')) return true
      return false
    },
    isFormChanged() {
      return JSON.stringify(this.form) !== JSON.stringify(this.initialForm) || this.logo_url !== this.data.logo
    }
  },
  components: {
    PButton,
    PAlert,
    PFRow,
    CurrencySelect,
    PEmoticonInput,
    Multiselect,
    PUploader,
    TimezoneSelect
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    date_type: {
      type: String,
      required: false,
    },
    enable_logo: {
      type: Boolean,
      required: true,
      default: false
    },
    enable_welcome_code: {
      type: Boolean,
      required: true,
      default: false
    },
    allows_custom_welcome_code: {
      type: Boolean,
      required: true,
      default: false
    },
    propose_welcome_code: {
      type: Function,
      required: true,
    },
    validate_welcome_code: {
      type: Function,
      required: true,
    },
    get_logo_upload_intent: {
      type: Function,
      required: true,
    },
    get_upload_intent_public_url: {
      type: Function,
      required: true,
    },
    published: {
      type: Boolean,
      required: true,
    },
    promo: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  data() {
    return {
      initialForm: {},
      form: {
        name: null,
        start_at: null,
        end_at: null,
        subtitle: null,
        welcome_code: null,
        participants_count: null,
        timezone: null,
        logo: null,
        currency: null,
      },
      welcome_codes: {
        loading: false,
        original: null,
        manual: false,
        input: '',
        error: null,
        fetched: [],
      },
      logo_url: null,
    }
  },
  emits: ['save', 'updatePreview'],
  setup() {
    return {v$: useVuelidate()}
  },
  mounted() {
    this.onReset()
  },
  validations() {
    return {
      form: {
        name: {
          required$,
          minLength: minLength$(3),
          maxLength: maxLength(this.hasPerm('advanced.bypass_char_limits') ? 255 : 44)
        },
        start_at: {required$},
        end_at: {},
        subtitle: {
          required$,
          minLength: minLength(1),
          maxLength: maxLength(this.hasPerm('advanced.bypass_char_limits') ? 255 : 56),
        },
        timezone: {required$},
        currency: {requiredIf: requiredIf(() => !this.promo)},
        welcome_code: {minLength: minLength(9), maxLength: maxLength(9)},
        participants_count: {},
        logo: {}
      }
    }
  },
  methods: {
    ...mapActions('eventWizard', ['fetchPublishValidation']),
    onReset() {
      this.form = {...this.data}
      this.initialForm = JSON.parse(JSON.stringify(this.form))
      this.logo_url = this.form.logo
      this.welcome_codes.input = this.form.welcome_code
      this.welcome_codes.original = this.form.welcome_code
      if (this.allows_custom_welcome_code) this.welcome_codes.manual = true
      this.previewFieldUpdated()
      this.askForAvailableWelcomeCode()
    },

    async onSave() {
      if (!await this.v$.$validate() && !this.hasPerm('advanced.force_publish')) return
      if (this.form.start_at === '') this.form.start_at = null
      if (this.form.end_at === '') this.form.end_at = null

      await this.emitPromised('save', this.form)
      await this.$nextTick()
      await this.fetchPublishValidation()
      this.onReset()
    },

    async newLogoUploaded() {
      await this.$nextTick()
      if (this.form.logo === DELETE_KEY)
        this.logo_url = ''
      else {
        this.logo_url = await this.get_upload_intent_public_url(this.form.logo)
      }
      this.previewFieldUpdated()
    },

    async previewFieldUpdated() {
      await this.$nextTick()
      this.$emit('updatePreview', {
        name: this.form.name,
        subtitle: this.form.subtitle,
        logo: this.logo_url,
        welcome_code: this.form.welcome_code,
      })
    },

    async onChangeCustomWelcomeCode(newCode) {
      this.welcome_codes.loading = true
      try {
        this.welcome_codes.error = null
        if (newCode.length === 0) return
        await this.validate_welcome_code(newCode)
        this.form.welcome_code = newCode
        this.welcome_codes.loading = false
      } catch (e) {
        if (e instanceof ValidationError) {
          let error = e.message.detail
          if (error === 'custom_code_not_allowed')
            error = this.$pgettext('event_wizard', 'Custom welcome code not allowed')
          if (error === 'invalid_format')
            error = this.$pgettext('event_wizard', 'Invalid format')
          if (error === 'not_available')
            error = this.$pgettext('event_wizard', 'Welcome code not available')
          this.welcome_codes.error = error
        } else throw e
        this.welcome_codes.loading = false
      }

    },

    async askForAvailableWelcomeCode() {
      if (!this.enable_welcome_code) return

      if ((this.form.name.length < 3) || (this.form.start_at.length < 3)) {
        this.welcome_codes.fetched = []
        this.welcome_codes.loading = false
        return
      }

      await this.$nextTick()

      this.welcome_codes.loading = true
      this.welcome_codes.fetched = await this.propose_welcome_code({
        name: this.form.name,
        start_at: this.form.start_at
      })
      this.welcome_codes.loading = false
    },

    onSetDate(value, key) {
      if (this.date_type === dateTypes.single_day || !this.date_type) {
        this.form.start_at = value
        this.form.end_at = value
      } else if (this.date_type === dateTypes.multi_day) {
        this.form.start_at = value.start
        this.form.end_at = value.end
      } else if (this.date_type === dateTypes.multi_day_with_time) {
        if (key === 'start_at')
          this.form.start_at = value
        else
          this.form.end_at = value
      }
      this.askForAvailableWelcomeCode()
    }
  }
}
</script>