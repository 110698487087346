<template>
  <div>
    <CContainer>
      <div class="grid-stripped">
        <PFRow :label="$pgettext('event_settings.label', 'Show `Use GetPica for your event` banner')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.show_use_get_pica_for_your_event"/>
        </PFRow>
        <PFRow :label="$pgettext('event_settings.label', 'Show `Share with a friend` banner')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.show_share_with_friends"/>
        </PFRow>
        <PFRow :label="$pgettext('event_settings.label', 'Add Logo GetPica in the upper left of every photo')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.apply_pica_logo_on_photo"/>
        </PFRow>
        <PFRow :label="$pgettext('event_settings.label', 'HD Deletion Date')">
          <VueCtkDateTimePicker
              v-model="form.hd_deletion_date"
              format="YYYY-MM-DD HH:mm:ss"
              formatted="LL HH:mm:ss"
          />
        </PFRow>
        <PFRow :label="$pgettext('event_settings.label', 'Last stay for all media deletion date')">
          <VueCtkDateTimePicker
              v-model="form.last_stay_deletion_date"
              format="YYYY-MM-DD HH:mm:ss"
              formatted="LL HH:mm:ss"
          />
        </PFRow>
        <PFRow :label="$pgettext('event_settings.label', 'Media Deletion Date ( for checkin past last stay )')">
          <VueCtkDateTimePicker
              v-model="form.media_deletion_date"
              format="YYYY-MM-DD HH:mm:ss"
              formatted="LL HH:mm:ss"
          />
        </PFRow>
        <PFRow :label="$pgettext('event_settings.label', 'Disable checkin from App')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="form.checkin_from_app_disabled"/>
        </PFRow>
        <PFRow :label="$pgettext('event_settings.label', 'Photo Cluster size')">
          <CSelect v-model="form.photo_cluster_size" :options="clusterSizes"/>
        </PFRow>
        <PFRow :label="$pgettext('event.modal.create_new_event.label','Currency')">
          <CurrencySelect v-model="form.currency"/>
        </PFRow>
        <PFRow :label="$pgettext('event_settings.label', 'Tag Categories')">
          <TagSummary :tags="form.tags" class="mb-2"/>
          <TagEditor ref="tag-editor" @save="form.tags = $event"/>
          <CButton @click="openTagEditor" color="primary">Edit Tags</CButton>
        </PFRow>
        <template v-if="hasPerm('advanced.change_contract')">
          <PFRow :label="$pgettext('event_settings.label','Contract Manager')">
            <PSelectKeyValue :options="searchContractManager" :value.sync="form.contract_manager"
                             placeholder="Select Contract Manager" @input="onChangeContractManager"/>
            <a href="#" @click.prevent="openContractManager" v-translate translate-context="event_wizard"
               v-if="form.contract_manager">Apri contract managerr</a>
          </PFRow>
          <PFRow :label="$pgettext('event_settings.label','Contract')" v-if="form.contract_manager">
            <PSelectKeyValue :options="onSearchContract" :value.sync="form.contract" placeholder="Select Contract"/>
          </PFRow>
        </template>
        <template v-if="hasPerm('advanced.change_event_owner')">
          <PFRow :label="$pgettext('event_settings.label','Event Owner')">
            <PSelectKeyValue :options="searchEventOwner" :value.sync="form.event_owner"
                             placeholder="Select Event Owner"/>
          </PFRow>
        </template>
      </div>
    </CContainer>
    <div class="d-flex justify-content-end p-3 border-top">
      <CButton color="success" variant="outline" @click="onReset" ref="reset-button" class="mr-2"
               v-translate translate-context="event_wizard">
        Reset
      </CButton>
      <CButton color="primary" @click="onSave" ref="save-button" v-translate translate-context="event_wizard">
        Save
      </CButton>
    </div>
  </div>
</template>

<script>
import PFRow from "@/domain/core/components/PFRow.vue";
import TagSummary from "@/domain/eventWizard/components/TagSummary.vue";
import TagEditor from "@/domain/eventWizard/components/TagEditor.vue";
import {mapActions, mapGetters} from "vuex";
import CurrencySelect from "@/domain/core/components/CurrencySelect.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";

export default {
  name: "StepEventSettings",
  components: {PSelectKeyValue, CurrencySelect, TagEditor, TagSummary, PFRow},
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      form: {
        show_use_get_pica_for_your_event: null,
        show_share_with_friends: null,
        apply_pica_logo_on_photo: null,
        hd_deletion_date: null,
        last_stay_deletion_date: null,
        media_deletion_date: null,
        checkin_from_app_disabled: null,
        photo_cluster_size: null,
        currency: null,
        tags: {},
      }
    }
  },
  emits: ['save'],
  async mounted() {
    await this.fetchClusterSizes()
    this.onReset()
  },
  computed: {
    ...mapGetters('core', [
      'recognitionClasses',
      'clusterSizes',
    ]),
  },
  methods: {
    ...mapActions('core', [
      'fetchRecognitionClasses',
      'fetchClusterSizes',
    ]),
    ...mapActions('eventWizard', [
      'searchContractManager',
      'searchContract',
      'searchEventOwner',
    ]),
    onReset() {
      this.form = {...this.data}
    },
    async onSave() {
      await this.emitPromised('save', this.form)
      this.onReset()
    },
    openTagEditor() {
      this.$refs['tag-editor'].open(this.form.tags)
    },
    onSearchContract(query) {
      if (!this.form.contract_manager)
        return []
      return this.searchContract({
        contractManager: this.form.contract_manager,
        query: query
      })
    },
    onChangeContractManager() {
      this.form.contract = null
    },
    openContractManager() {
      const url = this.$router.resolve({
        name: 'contract_manager.detail',
        params: {id: this.form.contract_manager}
      }).href;
      window.open(url, '_blank');
    }
  }
}
</script>