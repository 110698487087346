import {translate} from "vue-gettext";

const {gettext: $gettext} = translate;

export default {
    pdf: 'PDF',
    image: 'JPG',
    options() {
        return [
            {
                id: this.pdf,
                name: $gettext('PDF')
            },
            {
                id: this.image,
                name: $gettext('Image')
            },
        ]
    },
    getDefault() {
        return this.pdf
    }
}