<template>
  <PTransparentLoader :loading="loading" class="event-wizard">
    <CContainer class="p-0">
      <h1 class="main-header mb-1" v-translate translate-context="event_wizard">Folder Mapping</h1>
      <CRow class="">
        <CCol>
          <p v-translate translate-context="event_wizard.subtitle">
            Map the upload folder with the respective tags</p>
          <CLink class="form-generator__add-field" @click="addFolderMapping">
            <CIcon class="text-primary mr-1" size="xl" name="cipPlusCircle"/>
            <span v-translate>Add Folder Mapping</span>
          </CLink>
        </CCol>
      </CRow>
      <draggable v-model="folderMappings" handle=".handle" ghost-class="ghost">
        <transition-group type="transition" :name="'flip-list'">
          <div class="form-generator__field" v-for="folderMapping in folderMappings" :key="folderMapping.id">
            <div class="flex-grow-1 mr-4 cursor-pointer" @click="editFolderMapping(folderMapping)">
              <Detail :data="folderMapping"/>
            </div>
            <div class="handle p-3">
              <CIcon size="lg" name="cip-hamburger"/>
            </div>
            <div class="mr-3">
              <CButtonClose class="list-item-closer" @click="deleteFolderMapping(folderMapping)">
                <CIcon name="cipClose" size="custom-size" width="15"/>
              </CButtonClose>
            </div>
          </div>
        </transition-group>
      </draggable>
    </CContainer>

    <div class="event-wizard__footer">
      <CRow>
        <CCol>
          <div class="d-flex justify-content-end">
            <div class="mr-2">
              <CButton color="secondary" block @click="onReset" ref="reset-button"
                       v-translate translate-context="event_wizard">
                Reset
              </CButton>
            </div>
            <div>
              <CButton color="primary" @click="onSave" block ref="save-button"
                       v-translate translate-context="event_wizard">
                Save
              </CButton>
            </div>

          </div>
        </CCol>
      </CRow>
    </div>

    <Editor
        :availableTags="availableTags"
        @save="saveFolderMapping"
        ref="editor"
    />
  </PTransparentLoader>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import PTransparentLoader from "@/domain/core/components/PTransparentLoader.vue";
import {v4 as uuid} from "uuid";
import Detail from "@/domain/eventWizard/components/folderMapping/Detail.vue";
import Editor from "@/domain/eventWizard/components/folderMapping/Editor.vue";
import {cloneDeep} from "lodash";
import draggable from 'vuedraggable'

export default {
  name: "StepFolderMapping",
  components: {draggable, Editor, Detail, PTransparentLoader},
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    availableTags: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      folderMappings: [],
    }
  },
  emits: ['save', 'updatePreview'],
  setup() {
    return {v$: useVuelidate()}
  },
  mounted() {
    this.onReset()
  },
  methods: {
    onReset() {
      if (!this.data.folder_mapping) {
        this.folderMappings = []
        return
      }
      this.folderMappings = cloneDeep(this.data.folder_mapping).sort((a, b) => a.order - b.order)
    },

    async onSave() {
      this.updateSorting()
      await this.emitPromised('save', {
        folder_mapping: this.folderMappings.map(folderMapping => {
          // The id is valid and should be kept only if it's a "valid" id, and not a "fake" uuid used only for differentiate element in the loop
          return {
            ...folderMapping,
            id: folderMapping.isNew ? null : folderMapping.id,
          }
        })
      })
      this.onReset()
    },

    addFolderMapping() {
      this.$refs.editor.open({
        id: uuid(),
        folder: '',
        tags: [],
        isNew: true,
        order: this.folderMappings.length + 1,
      }, true)
    },
    saveFolderMapping(folderMapping) {
      let found = false
      this.folderMappings = this.folderMappings.map(p => {
        if (p.id === folderMapping.id) {
          found = true
          return folderMapping
        }
        return p
      })
      if (!found)
        this.folderMappings.push(folderMapping)
    },
    editFolderMapping(folderMapping) {
      this.$refs.editor.open(folderMapping, false)
    },
    deleteFolderMapping(folderMapping) {
      this.folderMappings = this.folderMappings.filter(p => p.id !== folderMapping.id)
    },
    updateSorting() {
      let sort = 1
      this.folderMappings = this.folderMappings.map((folderMapping) => {
        folderMapping.order = sort++
        return folderMapping
      })
    }
  }
}
</script>

<style scoped>

</style>