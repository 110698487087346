<template>
   <CRow>
    <CCol sm="4" >
      <div class="p-3 font-weight-bold">{{ key }}</div>
    </CCol>
    <CCol sm="4">
      <div class="p-3"><translate>Consent</translate></div>
    </CCol>
    <!-- click.stop prevents event bubbling -->
    <CCol sm="4" @click.stop="" style="cursor: default">
      <div class="p-3">
      <CInputCheckbox :custom="true" :checked.sync="mandatory"
                      :label="$pgettext('form_configurator','Field is mandatory')"  @update:checked="save()"/>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import {fieldOptions} from "@/domain/formConfigurator/fields";

export default {
  name: "CompactConsentInput",
  props: {
    data: {
      type: Object,
      required: true
    },
    fieldType:{
      type: String,
      required: true
    }
  },
  computed: {

  },
  data() {
    return {
      key: '',
      mandatory: false,
    }
  },
  emits: ['save'],
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.key = this.data.key ?? ''
      this.mandatory = this.data.mandatory ?? false
    },
    save() {
      this.$emit('save', {
        mandatory: this.mandatory,
      })
    }
  }
}
</script>