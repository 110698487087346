<template>
  <CCol md="6" xl="4" class="mb-3" v-if="galleriesPricelist.length > 0">
    <div class="overview-widget galleries">
      <div class="overview-widget__head">
        <div class="d-flex align-items-center">
          <h5 class="m-0" v-translate>Set the price list</h5>
          <PTooltip class="ml-2" :content="$pgettext('overview.tooltip', 'Set your price list to sell your photos')" :color="tooltipColor" placement="top"/>
        </div>
      </div>
      <div class="overview-widget__img">
        <CImg :src="require('@/assets/images/overview/pl.png')" fluid block class="w-100"/>
      </div>
      <div class="overview-widget__body">
        <label class="small" v-translate translate-context="event_wizard.overview">Choose a gallery</label>
        <GalleriesDropDown :galleries="galleriesPricelist"
                           showMissingPriceList
                           icon="cipArrowNarrowUpRight" @click="routeToGallery"/>

        <PAlert color="warning" icon="cipAlertTriangle">
          <template v-translate translate-context="event_wizard.overview">
           The price list for some galleries is not yet active, photos will not be delivered until it is set
          </template>
        </PAlert>
      </div>
    </div>
  </CCol>
</template>

<script>
import {mapGetters} from "vuex";
import PTooltip from "@/domain/core/components/PTooltip.vue";
import {tooltipColor} from "@/domain/eventWizard/components/overview/tooltip";
import GalleriesDropDown from "@/domain/eventWizard/components/overview/GalleriesDropDown.vue";
import PAlert from "@/components/PAlert.vue";

export default {
  name: "GalleriesPricelist",
  components: {PAlert, GalleriesDropDown, PTooltip},
  computed: {
    ...mapGetters('event', [
      'picaServiceId',
    ]),
    ...mapGetters('eventGallery', [
      'galleries',
    ]),
    galleriesPricelist() {
      return this.galleries.filter(g => g.missing_price_list || g.sub.some(s => s.missing_price_list))
    },
    tab() {
      let route = this.$route.matched.find(m => m.props?.default?.tab)
      if (route) return route.props.default.tab
      return "price_list"
    },
    tooltipColor() {
      return tooltipColor
    }
  },
  methods: {
    routeToGallery(gallery) {
      this.$router.push({name: `eventWizard.gallery.${this.tab}`, params: {picaServiceId: this.picaServiceId, galleryId: gallery.id}})
    }
  }
}
</script>


<style scoped>

</style>