<template>
  <CRow>
    <CCol sm="6">
      <div class="p-3 font-weight-bold">{{ name }}</div>
    </CCol>
    <!-- click.stop prevents event bubbling -->
    <CCol sm="auto" @click.stop="" style="cursor: default">
      <div class="p-3 d-flex align-items-center">
        <label v-translate translate-context="form_configurator" class="mr-2" style="font-size: 12px">Required</label>
        <CSwitch :checked.sync="mandatory" variant="3d" color="primary" size="sm" :label="$pgettext('form_configurator','Field is mandatory')"
                 @update:checked="save()"/>
      </div>
    </CCol>
  </CRow>

</template>

<script>
import {fieldOptions} from "@/domain/formConfigurator/fields";

export default {
  name: "CompactTextInput",
  props: {
    data: {
      type: Object,
      required: true
    },
    fieldType: {
      type: String,
      required: true
    }
  },
  computed: {
    fieldTypeLabel() {
      return fieldOptions.find(option => option.value === this.fieldType).label
    }
  },
  data() {
    return {
      name: '',
      mandatory: false,
    }
  },
  emits: ['save'],
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.name = this.data.name?.['it'] ?? ''
      this.mandatory = this.data.mandatory ?? false
    },
    save() {
      this.$emit('save', {
        mandatory: this.mandatory,
      })
    },
  }
}
</script>