import {translate} from 'vue-gettext'
import Palette from "@/domain/core/utils/chart/Palette";

const {pgettext: $pgettext} = translate

export const Settings = {
    summary: {
        orders: {
            header: $pgettext('stats.summary.orders.header', 'Transacted'),
            tooltip: '',
            palette: {
                name: 'blue',
                color: Palette.blue[0],
            },
            qty: {
                title: $pgettext('stats.summary.orders.qty.title', 'Orders'),
                tooltip: '',
            },
            conversion_rate: {
                title: $pgettext('stats.summary.orders.conversion_rate.title', 'CR/Check-ins'),
                tooltip: $pgettext('stats.summary.orders.conversion_rate.tooltip', 'Percentage of activations over estimated participants.'),
            }
        },
        checkins: {
            header: $pgettext('stats.summary.checkins.header', 'Check In'),
            tooltip: $pgettext('stats.summary.checkins.tooltip', 'Number of event participants who accessed their album.'),
            palette: {
                name: 'purple',
                color: Palette.purple[0],
            },
            qrcode: {
                title: $pgettext('stats.summary.checkins.qrcode.title', 'Access via QR Code'),
                tooltip: '',
            },
            direct_link: {
                title: $pgettext('stats.summary.checkins.direct_link.title', 'Access via Direct Link'),
                tooltip: '',
            }
        },
        engagement: {
            header: $pgettext('stats.summary.engagement.header', 'Total Shared'),
            tooltip: '',
            palette: {
                name: 'orange',
                color: Palette.orange[0],
            },
            social: {
                title: $pgettext('stats.summary.engagement.social.title', 'Shared on socials'),
                tooltip: '',
            },
            download: {
                title: $pgettext('stats.summary.engagement.download.title', 'Media Downloaded'),
                tooltip: '',
            }
        },
        media: {
            header: $pgettext('stats.summary.media.header', 'Total Uploaded'),
            tooltip: '',
            palette: {
                name: 'fuchsia',
                color: Palette.fuchsia[0],
            },
            per_user: {
                title: $pgettext('stats.summary.media.per_user.title', 'Media per user'),
                tooltip: '',
            },
        }
    },
}

