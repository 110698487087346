<template>
  <div class="form-generator">



    <draggable v-model="rows" @end="updateSorting" handle=".handle" ghost-class="ghost">
      <div v-for="input in rows" :key="input.id" :class="['form-generator__field']">
        <div class="flex-grow-1 mr-4" @click="editRow($event,input)" :class="[{'editable': input.editable}]">
          <CompactConsentInput :data="input"
                            :field-type="input.type"
                            @save="updateRow(input.id,$event)"/>
        </div>
        <div class="handle p-3">
          <CIcon size="lg" name="cip-hamburger"/>
        </div>
        <CButtonClose  class="list-item-closer mr-2" @click="deleteRow($event,input.id)" v-if="input.deletable">
          <CIcon name="cipClose" size="custom-size" width="15" />
        </CButtonClose>
      </div>
    </draggable>

     <CLink class="form-generator__add-field" @click="addRow">
      <CIcon class="text-primary mr-1" size="xl" name="cipPlusCircle"/>
          <span v-translate><b>Add</b> consent</span>
    </CLink>

    <EditConsent
        v-if="showEditor"
        :data="row"
        :advanced="advanced"
        :langs="langs"
        :get_consent_upload_intent="get_consent_upload_intent"
        @save="saveFromModal"
        @cancel="showEditor = false"
    />
  </div>
</template>

<script>
import EditConsent from "@/domain/formConfigurator/component/EditConsent.vue";
import CompactConsentInput from "@/domain/formConfigurator/compact/CompactConsentInput.vue";
import draggable from 'vuedraggable'
import {methods as rows_methods} from "@/domain/formConfigurator/component/rows";

export default {
  name: "Consents",
  emits: ['update:data','preview'],
  components: {CompactConsentInput, draggable, EditConsent},
  props: {
    data: {
      type: Array,
      required: true
    },
    get_consent_upload_intent: {
      type: Function,
      required: true
    },
    advanced: {
      type: Boolean,
      required: true
    },
    langs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showEditor: false,
      rows: [],
      row: {},
    }
  },
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...rows_methods
  }
}
</script>