<template>
  <CModal :title="title" :show="true" @update:show="close" size="lg" centered>
    <CRow class="mb-3">
      <CCol col="12">
        <label v-translate translate-context="event_wizard.email_schedule.new.label">Send From</label>
        <VueCtkDateTimePicker
            v-model="form.schedule_from"
            :no-label="true"
            :label="$gettext('Select date')"
            format="YYYY-MM-DD"
            formatted="LL"
            :only-date="true"
            :no-button-now="true"
            color="#1867FF"
        />
      </CCol>
      <CCol col="12">
        <label v-translate translate-context="event_wizard.email_schedule.new.label">Send Until</label>
        <VueCtkDateTimePicker
            v-model="form.schedule_to"
            :no-label="true"
            :label="$gettext('Select date')"
            format="YYYY-MM-DD"
            formatted="LL"
            :only-date="true"
            :no-button-now="true"
            color="#1867FF"
        />
      </CCol>
      <CCol col="12">
        <label v-translate translate-context="event_wizard.email_schedule.new.label">Send At</label>
        <VueTimepicker
            v-model="form.send_time"
            :minute-interval="60"
            :label="$gettext('Select time')"
            close-on-complete
            auto-scroll
        />
      </CCol>
    </CRow>

    <template #footer>
      <CButton @click="close" color="secondary" v-translate>Cancel</CButton>
      <CButton @click="onSave" color="primary" :disabled="v$.$invalid" v-translate>Save</CButton>
    </template>
  </CModal>
</template>

<script>

import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: "EditEmailSchedule",
  emits: ['save', 'cancel'],
  components: { VueTimepicker },
  data() {
    return {
      form: {
        schedule_from: null,
        schedule_to: null,
        send_time: null,
      },
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  computed: {
    title() {
      if (this.isNewOption)
        return this.$pgettext('event_wizard.email_schedule.new.title', 'Add new option')
      else
        return this.$pgettext('event_wizard.email_schedule.new.title', 'Edit option')
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  mounted() {
    this.onReset()
  },
  validations() {
    return {
      form: {
        schedule_from: {required},
        schedule_to: {required},
        send_time: {required},
      }
    }
  },

  methods: {
    async onReset() {
      this.form = {
        ...this.data,
        schedule_from: this.data.schedule_from ? this.data.schedule_from.format('Y-M-D') : null,
        schedule_to: this.data.schedule_to ? this.data.schedule_to.format('Y-M-D') : null,
      }
    },
    async onSave() {
      if (typeof this.form.send_time === 'object')
          this.form.send_time = this.form.send_time.HH + ':' + this.form.send_time.mm

      await this.emitPromised('save', {
        ...this.form
      })
      this.close()
      await this.onReset()
    },
    close() {
      this.$emit('cancel')
    }
  }
}
</script>


<style scoped>

</style>