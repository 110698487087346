import {translate} from "vue-gettext";

const {gettext: $gettext} = translate;

export default {
    pica_code_generation_in_progress: 'gp',
    pica_code_generation_completed: 'gc',
    label_in_progress: 'lp',
    label_completed: 'lc',
    none: 'n',

    options() {
        return [
            {
                id: this.pica_code_generation_in_progress,
                name: $gettext('Pica Codes generation in progress...')
            },
            {
                id: this.pica_code_generation_completed,
                name: $gettext('Picacode generation completed')
            },
            {
                id: this.label_in_progress,
                name: $gettext('Label generation in progress')
            },
            {
                id: this.label_completed,
                name: $gettext('Label generation completed')
            },
            {
                id: this.none,
                name: ''
            },

        ]
    },
}