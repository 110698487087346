<template>
  <PTransparentLoader :loading="loading" class="event-wizard">
    <LabelsGenerationModal @generate="onSave" ref="labelsGenerationModal"/>
    <ReleaseDetailModal @update="onSave" ref="releaseDetailModal"/>
    <CreateNewGalleryModal ref="createNewGalleryModal" @created="onGalleryCreated"/>
    <div>
      <h1 class="main-header" v-translate translate-context="event_wizard">Pica Codes</h1>
      <CCard>
        <CCardHeader>
          <h5 class="my-2" v-translate>All releases</h5>
          <div class="card-header-actions">
            <PButton v-if="canGenerateAllLabels"
                     @click="showGenerateAllLabels"
                     class="btn btn-sm btn-outline-success mr-2"
                     spinnerColor="success">
              <translate>Generate all labels</translate>
              ({{ releaseIds.length }})
            </PButton>
            <PButton v-if="canRegenerateAllLabels"
                     @click="showGenerateAllLabels"
                     class="btn btn-sm btn-outline-success mr-2"
                     spinnerColor="success">
              <translate>Regenerate all labels</translate>
              ({{ releaseIds.length }})
            </PButton>
            <PButton
                :loading="downloadingReleases"
                @click="onDownloadReleases"
                class="btn btn-sm btn-outline-primary"
                spinnerColor="primary"
                v-if="hasPerm('pica_code.download_releases')">
              <translate>Download codes</translate>
            </PButton>
            <PButton
                :loading="downloadingLabels"
                @click="onDownloadLabels"
                class="btn btn-sm btn-outline-primary"
                spinnerColor="primary"
                v-if="hasPerm('pica_code.download_labels')">
              <translate>Download labels</translate>
            </PButton>
          </div>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
              :hover="false"
              :bordered="true"
              :items="data.releases"
              :fields="fields"
              striped
              class="custom-table custom-table--clean"
          >
            <template #race_number="{item}">
              <td>
                {{ item.race_number.from }} - {{ item.race_number.to }}
              </td>
            </template>
            <template #print_seq="{item}">
              <td>
                {{ item.print_seq.from }} - {{ item.print_seq.to }}
              </td>
            </template>
            <template #labels_layout="{item}">
              <td>
                <LayoutPreview :data="layout(item.labels_layout)"/>
              </td>
            </template>
            <template #csv="{ item }">
              <td>
                <CLink @click="onDownloadRelease(item.id)">
                  <CIcon name="cipDoc" size="custom-size" :height="24"/>
                </CLink>
              </td>
            </template>
            <template #zip="{ item }">
              <td>
                <CLink @click="onDownloadLabel(item.id)">
                  <CIcon name="cipZip" size="custom-size" :height="22"/>
                </CLink>
              </td>
            </template>
            <template #status="{item}">
              <td>
                <TaskStatus :data="task(item.current_task_id)" v-if="item.current_task_id">
                  <template #in-progress>
                    <div v-if="item.status === releaseStatus.pica_code_generation_in_progress">
                      <span style="font-weight:5600" v-translate translate-context="pica_code">Pica Codes generation in progress...</span>
                    </div>
                    <div v-if="item.status ===releaseStatus.label_in_progress">
                      <span style="font-weight:5600" v-translate
                            translate-context="pica_code">Generating labels...</span>
                    </div>
                  </template>
                  <template #failed>
                    <div v-if="item.status ===releaseStatus.pica_code_generation_in_progress">
                      <span v-translate translate-context="pica_code">Pica Code generation failed</span>
                    </div>
                    <div v-if="item.status ===releaseStatus.label_in_progress">
                      <span v-translate translate-context="pica_code">Label generation failed</span>
                    </div>
                  </template>
                </TaskStatus>
                <template v-else-if="item.status === releaseStatus.pica_code_generation_completed">
                  <CButton v-if="hasPerm('pica_code.generate_labels')"
                           @click="showGenerateLabelsModal(item)" variant="outline"
                           color="success" v-translate>
                    Generate
                  </CButton>
                </template>
                <template v-else-if="item.status === releaseStatus.label_completed">
                  <CButton v-if="hasPerm('pica_code.regenerate_labels')"
                           @click="showGenerateLabelsModal(item)" variant="outline"
                           color="success" v-translate>
                    Regenerate
                  </CButton>
                </template>
              </td>
            </template>
            <template #actions="{item}">
              <td class="text-right text-nowrap">
                <CButton @click="showDetailModal(item.id)" color="success"
                         v-translate translate-context="list.actions" class="mr-2">Detail
                </CButton>
                <CButton @click="onDelete(item.id)" color="danger"
                         v-if="hasPerm('pica_code.delete')"
                         v-translate translate-context="list.actions">Delete
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>


    </div>
  </PTransparentLoader>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import PTransparentLoader from "@/domain/core/components/PTransparentLoader.vue";
import {mapActions, mapGetters} from "vuex";
import PButton from "@/components/PButton.vue";
import releaseStatus from "@/domain/core/constant/releaseStatus";
import TaskStatus from "@/domain/picaCode/components/TaskStatus.vue";
import LabelsGenerationModal from "@/domain/picaCode/components/LabelsGenerationModal.vue";
import LayoutPreview from "@/domain/picaCode/components/LayoutPreview.vue";
import ReleaseDetailModal from "@/domain/picaCode/components/ReleaseDetailModal.vue";
import CreateNewGalleryModal from "@/domain/eventGallery/components/CreateNewGalleryModal.vue";

export default {
  name: "StepPicaCodes",
  components: {
    CreateNewGalleryModal,
    ReleaseDetailModal,
    LayoutPreview,
    LabelsGenerationModal,
    TaskStatus,
    PButton,
    PTransparentLoader
  },
  data() {
    return {
      downloadingReleases: false,
      downloadingLabels: false,
      releaseOpened: null,
    }
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
  },
  emits: ['save'],
  computed: {
    fields() {
      let fields = []
      fields.push({key: 'id', label: this.$pgettext('pica_codes.list', 'ID'), _style: 'width:100px;'})
      fields.push({key: 'description', label: this.$pgettext('pica_codes.list', 'Description')})
      fields.push({key: 'quantity', label: this.$pgettext('pica_codes.list', 'Quantity')})
      if (this.usesFaceRecognition)
        fields.push({key: 'welcome_code', label: this.$pgettext('pica_codes.list', 'Welcome Code')})
      else {
        fields.push({key: 'race_number', label: this.$pgettext('pica_codes.list', 'Race Number')})
        if (this.hasPerm('pica_code.see_print_sequence'))
          fields.push({key: 'print_seq', label: this.$pgettext('pica_codes.list', 'Print sequence')})
        fields.push({key: 'labels_layout', label: this.$pgettext('pica_codes.list', 'Layout')})
      }
      fields.push({key: 'gallery', label: this.$pgettext('pica_codes.list', 'Gallery')})
      if (this.hasPerm('pica_code.download_releases'))
        fields.push({key: 'csv', label: this.$pgettext('pica_codes.list', 'CSV')})
      if (this.hasPerm('pica_code.download_labels'))
        fields.push({key: 'zip', label: this.$pgettext('pica_codes.list', 'ZIP')})
      fields.push({key: 'status', label: this.$pgettext('pica_codes.list', 'Status'), _style: 'width:100px;'})
      fields.push({
        key: 'actions',
        label: this.$pgettext('pica_codes.list', 'Actions'),
        _style: 'width:200px;text-align:center;'
      })
      return fields
    },
    releaseStatus() {
      return releaseStatus
    },
    releaseIds() {
      return this.data.releases.map(release => release.id)
    },
    canGenerateAllLabels() {
      if (this.usesFaceRecognition) return false
      if (this.data.releases.filter(release => release.status === releaseStatus.label_completed).length > 0) return false
      return this.hasPerm('pica.generate_labels')
    },
    canRegenerateAllLabels() {
      if (this.usesFaceRecognition) return false
      if (this.data.releases.filter(release => release.status === releaseStatus.label_completed).length === 0) return false
      return this.hasPerm('pica.generate_labels')
    },
    ...mapGetters('event', [
      'usesFaceRecognition'
    ]),
    ...mapGetters('picaCode', [
      'layout',
      'task'
    ])
  },
  setup() {
    return {v$: useVuelidate()}
  },
  mounted() {
    this.onReset()
  },
  methods: {
    ...mapActions('picaCode', [
      'fetchLayouts',
      'fetchTasks',
      'downloadReleases',
      'downloadLabels',
      'deleteRelease',
      'fetchRelease',
      'fetchGalleries'
    ]),
    async onReset() {
      const layoutIds = this.data.releases.reduce((acc, release) => {
        if (release.labels_layout)
          acc[release.labels_layout] = true
        return acc
      }, {})
      await this.fetchLayouts(Object.keys(layoutIds))
      await this.updateTasks()
    },
    async onSave() {
      this.$emit('save', [])
      await this.onReset()
    },
    async onDelete(id) {
      this.confirm({
        title: this.$gettext('Delete'),
        message: this.$gettext('Are you sure you want to delete this release?'),
        cb_confirm: async () => {
          await this.deleteRelease(id)
          await this.onSave()
        }
      })
    },
    async onDownloadReleases() {
      this.downloadingReleases = true
      await this.downloadReleases(this.releaseIds)
      this.downloadingReleases = false
    },
    async onDownloadRelease(id) {
      await this.downloadReleases([id])
    },
    async onDownloadLabels() {
      this.downloadingLabels = true
      await this.downloadLabels(this.releaseIds)
      this.downloadingLabels = false
    },
    async onDownloadLabel(id) {
      await this.downloadLabels([id])
    },
    async updateTasks() {
      const taskIds = this.data.releases.map(release => release.current_task_id).filter(Boolean)
      if (!taskIds.length) return

      const tasks = await this.fetchTasks(taskIds)
      let hasIncompleteTasks = tasks.filter(task => !task.completed).length > 0
      if (hasIncompleteTasks) {
        setTimeout(() => {
          this.updateTasks()
        }, 1000)
      }
    },
    showGenerateLabelsModal(item) {
      this.$refs.labelsGenerationModal.open([item.id])
    },
    showGenerateAllLabels() {
      this.$refs.labelsGenerationModal.open(this.releaseIds)
    },
    showDetailModal(id) {
      this.$refs.releaseDetailModal.open(id)
      this.releaseOpened = id
    },
    openCreateGallery(params) {
      if (!params) params = {}
      this.$refs.createNewGalleryModal.open(params)
    },
    async onGalleryCreated() {
      this.$emit('save', [])
      if (this.releaseOpened) {
        await this.fetchGalleries(true)
        await this.$refs.releaseDetailModal.refreshGallery()
      }
    }
  }
}
</script>