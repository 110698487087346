import {translate} from 'vue-gettext';

const {pgettext: $pgettext} = translate;

const createStep = (target, title, content, placement='top') => ({
    target,
    header: {title: title},
    content: content,
    params: {
        enableScrolling: window.innerWidth < 576, // enable scrolling on mobile
        placement,
        highlight: true,
    },
})

export const overviewSteps = [
    createStep('[data-v-step="0"]', $pgettext('event_wizard.overview', 'Upload and deliver photos'), $pgettext('event_wizard.overview', 'Select the folder where you want to upload your event photos. Guests will be able to instantly view them on their smartphones'), 'top'),
    createStep('[data-v-step="1"]', $pgettext('event_wizard.overview', 'Access with link'), $pgettext('event_wizard.overview', 'Share the link via email, WhatsApp or SMS to your guests and invite them to access their photos in just one click'), 'top'),
    createStep('[data-v-step="2"]', $pgettext('event_wizard.overview', 'Access with QR code'), $pgettext('event_wizard.overview', 'Customize and download the QR code. Insert it on cards, flyers or screens to facilitate access to the photos for all participants'), 'top'),
    createStep('[data-v-step="3"]', $pgettext('event_wizard.overview', 'Monitor the event'), $pgettext('event_wizard.overview', 'Analyze your event performance with key statistics updated in real time'), 'bottom'),
]
