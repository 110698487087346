<template>
  <PTransparentLoader :loading="loading" class="event-wizard">
    <CContainer class="p-0">
      <h1 class="main-header mb-1" v-translate translate-context="event_wizard">Privacy</h1>
      <p>
        <translate translate-context="event_wizard.subtitle">
          Enable and choose custom privacy settings for your users
        </translate>
      </p>

      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Privacy policy')"
             :help-text="$pgettext('event_wizard.help', 'Select from a pre-existing setting')"
             separator
             label-class="big">
        <multiselect
            class="mb-2"
            :options="privacyPolicyTypeOptions"
            label="label"
            :searchable="false"
            track-by="value"
            :value="privacyPolicySelected"
            @input="(event) => v$.form.privacy_policy_type.$model = event.value"
            @select="previewFieldUpdated"
            :allow-empty="false"
            :show-labels="false"
        />
      </PFRow>

      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Checkbox')"
             :help-text="$pgettext('event_wizard.help', 'Make the field mandatory')"
             separator
             label-class="big" v-if="!isPrivacyPolicyManagedByPica">
        <CSwitch color="primary"
                 variant="3d" size="sm"
                 @update:checked="previewFieldUpdated"
                 :checked.sync="v$.form.show_privacy_checkbox.$model"/>

      </PFRow>
      <PFRow size="5x7" v-if="isCustomPrivacyPolicy" :label="$pgettext('event_wizard.label', 'Content')"
             label-class="big"
             separator
             :help-text="$pgettext('event_wizard.help', 'Create your own custom privacy text for the event')">
        <PTranslatableCkeditor
            v-model="v$.form.privacy_policy_text.$model"
            @input="previewFieldUpdated"
            :placeholder="$pgettext('event_wizard.placeholder','Privacy Link')"
        />

      </PFRow>
      <PFRow :label="$pgettext('event_wizard.label', 'Share e-mail privacy policy')"
             separator
             label-class="big" size="5x7">
        <multiselect
            class="mb-2"
            :options="sharePolicyTypeOptions"
            label="label"
            track-by="value"
            :searchable="false"
            :value="sharePolicySelected"
            @input="(event) => v$.form.share_policy_type.$model = event.value"
            @select="previewFieldUpdated"
            :allow-empty="false"
            :show-labels="false"
        />
      </PFRow>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Content')"
             :help-text="$pgettext('event_wizard.help', 'Create your own custom share privacy text for the event')"
             separator
             label-class="big" v-if="isCustomSharePolicy">
        <PTranslatableCkeditor
            v-model="v$.form.share_policy_text.$model"
            @input="previewFieldUpdated"
            :placeholder="$pgettext('event_wizard.placeholder','Share policy Link')"
        />
      </PFRow>
      <PFRow full-width v-if="sharePolicyIsEnabled" separator>
        <PButton
            color="primary"
            class="mr-3"
            :loading="shareDataInDownload"
            @click="downloadShareData">
          <translate translate-context="event_wizard">Show share policy</translate>
        </PButton>
      </PFRow>

      <h1 class="main-header mb-1" v-translate translate-context="event_wizard">Checkin</h1>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Pre Selfie Text')"
             :help-text="$pgettext('event_wizard.help', 'Custom text pre-selfie')"
             separator
             label-class="big">
        <PTranslatableCkeditor
            v-model="v$.form.pre_selfie_text.$model"
            @input="previewFieldUpdated"
            :placeholder="$pgettext('event_wizard.placeholder','Custom text pre-selfie')"
        />
      </PFRow>

      <h1 class="main-header mb-1" v-translate translate-context="event_wizard">Payment</h1>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Pre Payment Confirm Text')"
             :help-text="$pgettext('event_wizard.help', 'If present, this text will show the message and ask for confirmation before accepting an order')"
             separator
             label-class="big">
        <PTranslatableCkeditor
            v-model="v$.form.pre_payment_confirm_text.$model"
            @input="previewFieldUpdated"
            :placeholder="$pgettext('event_wizard.placeholder','Custom pre payment confirm text')"
        />
      </PFRow>
      <h1 class="main-header mb-1" v-translate translate-context="event_wizard">E-Mail customization</h1>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Credit - EMail')"
             :help-text="$pgettext('event_wizard.help', 'The field will be sent in the email to be used based on the template')"
             separator
             label-class="big">
        <CInput v-model="v$.form.credit_email.$model" @input="previewFieldUpdated"/>
      </PFRow>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Credit - Name')"
             :help-text="$pgettext('event_wizard.help', 'The field will be sent in the email to be used based on the template')"
             separator
             label-class="big">
        <CInput v-model="v$.form.credit_name.$model" @input="previewFieldUpdated"/>
      </PFRow>
    </CContainer>

    <div class="event-wizard__footer">
      <CRow>
        <CCol>
          <div class="d-flex justify-content-end">
            <div class="mr-2">
              <CButton color="secondary" block @click="onReset" ref="reset-button"
                       v-translate translate-context="event_wizard">
                Reset
              </CButton>
            </div>
            <div>
              <CButton color="primary" @click="onSave" block :disabled="v$.$invalid" ref="save-button"
                       v-translate translate-context="event_wizard">
                Save
              </CButton>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
  </PTransparentLoader>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {required, requiredIf} from "@vuelidate/validators";
import Multiselect from "vue-multiselect";
import PTranslatableCkeditor from "@/domain/core/components/PTranslatableCkeditor.vue";
import PTransparentLoader from "@/domain/core/components/PTransparentLoader.vue";
import PButton from "@/components/PButton.vue";
import PFRow from "@/domain/core/components/PFRow.vue";

export default {
  name: "StepCustomization",
  components: {PFRow, PButton, PTransparentLoader, PTranslatableCkeditor, Multiselect},
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      form: {
        privacy_policy_type: null,
        show_privacy_checkbox: false,
        privacy_policy_text: {
          it: '',
          en: '',
        },
        share_policy_type: null,
        share_policy_text: {
          it: '',
          en: '',
        },
        pre_selfie_text: {
          it: '',
          en: '',
        },
        pre_payment_confirm_text: {
          it: '',
          en: '',
        },
        credit_name: null,
        credit_email: null,
      },
      shareDataInDownload: false,
    }
  },
  emits: ['save', 'updatePreview', 'downloadShareData'],
  setup() {
    return {v$: useVuelidate()}
  },
  mounted() {
    this.onReset()
  },
  computed: {
    privacyPolicyTypeOptions() {
      return [
        {label: this.$pgettext('event_wizard.label', 'Pica is the privacy manager'), value: 'pica'},
        {
          label: this.$pgettext('event_wizard.label', 'Use the default privacy policy text'),
          value: 'event_owner'
        },
        {label: this.$pgettext('event_wizard.label', 'Use custom privacy policy for this event'), value: 'custom'},
      ]
    },
    sharePolicyTypeOptions() {
      return [
        {label: this.$pgettext('event_wizard.label', 'User e-mail will remain private'), value: 'no_share'},
        {
          label: this.$pgettext('event_wizard.label', 'Use the default share policy text'),
          value: 'event_owner'
        },
        {label: this.$pgettext('event_wizard.label', 'Use custom share policy for this event'), value: 'custom'},
      ]
    },
    isPrivacyPolicyManagedByPica() {
      return this.form.privacy_policy_type === 'pica'
    },
    isCustomPrivacyPolicy() {
      return this.form.privacy_policy_type === 'custom'
    },
    isCustomSharePolicy() {
      return this.form.share_policy_type === 'custom'
    },
    sharePolicyIsEnabled() {
      return this.form.share_policy_type !== 'no_share'
    },
    privacyPolicySelected() {
      return this.privacyPolicyTypeOptions.find(o => o.value === this.form.privacy_policy_type)
    },
    sharePolicySelected() {
      return this.sharePolicyTypeOptions.find(o => o.value === this.form.share_policy_type)
    },
  },
  validations() {
    return {
      form: {
        privacy_policy_type: {required},
        share_policy_type: {required},
        show_privacy_checkbox: {},
        privacy_policy_text: {requiredIf: requiredIf(() => this.isCustomPrivacyPolicy)},
        share_policy_text: {requiredIf: requiredIf(() => this.isCustomSharePolicy)},
        pre_selfie_text: {},
        pre_payment_confirm_text: {},
        credit_name: {},
        credit_email: {},
      }
    }
  },

  methods: {
    onReset() {
      this.form = {...this.data}
      this.previewFieldUpdated()
    },

    async onSave() {
      if (!await this.v$.$validate()) return
      await this.emitPromised('save', this.form)
      this.onReset()
    },

    async previewFieldUpdated() {
      await this.$nextTick()
      this.$emit('updatePreview', {
        privacy_policy_type: this.form.privacy_policy_type,
        privacy_policy_text: this.form.privacy_policy_text,
        show_privacy_checkbox: this.form.show_privacy_checkbox,
        share_policy_type: this.form.privacy_policy_type,
        share_policy_text: this.form.share_policy_text,
        pre_selfie_text: this.form.pre_selfie_text,
        pre_payment_confirm_text: this.form.pre_payment_confirm_text,
        credit_name: this.form.credit_name,
        credit_email: this.form.credit_email,
      })
    },

    async downloadShareData() {
      this.shareDataInDownload = true
      await this.$emit('downloadShareData')
      this.shareDataInDownload = false
    }
  }
}
</script>

<style scoped>

</style>