<template>
  <CContainer>
    <h1 class="main-header" v-translate translate-context="event_wizard">Event Setup</h1>
    <CCard>
      <CCardHeader>
        <PTabs :tabs="steps" :selected="step" @select="goToStep" pills />
      </CCardHeader>
      <CCardBody class="p-0">
        <StepTemplate
            v-if="step === 'template' && isVisible('template')"
            :data="form['template']"
            :loading="isStepLoading('template')"
            @save="saveStep"
        />
        <StepPlanSettings
            v-if="step === 'plan_settings' && isVisible('template')"
            :data="form['plan_settings']"
            :loading="isStepLoading('plan_settings')"
            @save="saveStep"
        />
        <StepVertical
            v-if="step === 'vertical' && isVisible('vertical')"
            :data="form['vertical']"
            :loading="isStepLoading('vertical')"
            @save="saveStep"
        />
        <StepEventSettings
            v-if="step === 'event_settings' && isVisible('event_settings')"
            :data="form['event_settings']"
            :loading="isStepLoading('event_settings')"
            @save="saveStep"
        />
      </CCardBody>
    </CCard>

  </CContainer>
</template>

<script>

import {mapGetters} from "vuex";
import PTabs from "@/domain/core/components/PTabs.vue";
import StepVertical from "@/domain/eventWizard/steps/StepVertical.vue";
import StepEventSettings from "@/domain/eventWizard/steps/StepEventSettings.vue";
import StepTemplate from "@/domain/eventWizard/steps/StepTemplate.vue";
import StepPlanSettings from "@/domain/eventWizard/steps/StepPlanSettings.vue";

export default {
  name: "TabConfiguration",
  components: {StepEventSettings, StepVertical, PTabs, StepPlanSettings, StepTemplate},
  props: {
    step: {
      type: String,
      required: true
    },
  },
  emits: ['save'],
  computed: {
    ...mapGetters('eventWizard', [
      'form',
      'isStepLoading',
      'isStepVisible',
      'eventId'
    ]),
    steps() {
      let steps = []
      if (this.isVisible('template'))
        steps.push({name: 'template', label: 'Template'})
      if (this.isVisible('plan_settings'))
        steps.push({name: 'plan_settings', label: 'Plan Settings'})
      if (this.isVisible('vertical'))
        steps.push({name: 'vertical', label: 'Vertical'})
      if (this.isVisible('event_settings'))
        steps.push({name: 'event_settings', label: 'Settings'})
      return steps
    }
  },
  methods: {
    isVisible(step) {
      return this.isStepVisible(step)
    },
    async saveStep(data) {
      await this.emitPromised('save', data)
    },
    async goToStep(step) {
      await this.$router.push({name: `eventWizard.settings.${step}`,params: {picaServiceId: this.eventId}})
    }
  }
}
</script>