<template>
  <CModal :show.sync="show" :title="$gettext('QR Code')" centered>
    <slot></slot>
    <template #footer>
      <CButton color="secondary" @click="show=false" v-translate>Close</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "QrCodeModal",
  data() {
    return {
      show: false
    }
  },
  methods: {
    async open() {
      this.show = true
    },
  }
}
</script>