<template>
  <div>
    <PFRow :label="$pgettext('event_wizard.email_schedule.schedule','E-Mail Type')">
      {{ data.schedule_type }}
    </PFRow>
    <PFRow :label="$pgettext('event_wizard.email_schedule.schedule','Schedule Period')">
      {{ data.schedule_from | formatDate('L LT', timeZone) }} - {{ data.schedule_to | formatDate('L LT',timeZone) }}
    </PFRow>
    <PFRow :label="$pgettext('event_wizard.email_schedule.schedule','Send At (every day)')" v-if="data.send_time">
      {{ data.send_time }}
    </PFRow>
    <PFRow :label="$pgettext('event_wizard.email_schedule.schedule','Send Every')" v-if="data.send_every">
      {{ data.send_every }} hours
    </PFRow>
    <PFRow :label="$pgettext('event_wizard.email_schedule.schedule','Ultima esecuzione')">
      <span v-if="data.last_run_at">
        {{ data.last_run_at | formatDateTime('L LT',timeZone) }}
      </span>
      <span v-else>
        <i class="fa fa-times text-info"></i> {{ $pgettext('event_wizard.email_schedule.schedule','Not executed yet') }}
      </span>
    </PFRow>
    <PFRow :label="$pgettext('event_wizard.email_schedule.schedule','Last sent')" v-if="data.last_run_at">
      <span v-if="data.last_sent_at">
        <i class="fa fa-check text-success"></i> {{ data.last_sent_at | formatDateTime('LLL',this.timeZone) }} ({{ data.email_sent_count }} emails sent)
      </span>
      <span v-else>
        <i class="fa fa-times text-info"></i> {{ $pgettext('event_wizard.email_schedule.schedule','Not sent yet') }}
      </span>
    </PFRow>
  </div>
</template>

<script>
import PFRow from "@/domain/core/components/PFRow.vue";
import {mapGetters} from "vuex";

export default {
  name: "EmailSchedule",
  components: {PFRow},
  computed: {
    ...mapGetters('event',['timeZone'])
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
