<template>
  <CCol md="6" xl="4" class="mb-3">
    <div class="overview-widget media-kit" data-v-step="1">
    <div class="overview-widget__head">
      <div class="d-flex align-items-center">
        <h5 class="m-0" v-translate>Direct link</h5>
        <PTooltip class="ml-2"
                  :content="$pgettext('overview.tooltip', 'Send this link via email, whatsapp or text message and guests will be able to access their photos in just one click.')"
                  :color="tooltipColor" placement="top"/>
      </div>
    </div>
    <div class="overview-widget__img">
      <CImg :src="require('@/assets/images/overview/dl.png')" fluid block class="w-100"/>
    </div>
    <div class="overview-widget__body">
      <label class="small d-block" v-translate translate-context="event_wizard.overview">Direct event link</label>
      <div class="d-flex align-items-center mb-1" v-if="showShortLink">
        <CInput :value="mediaKit.short_direct_link" readonly class="w-100 m-0"/>
        <div>
          <CButton color="dark" class="btn-circle ml-2" variant="outline" size="sm"
                   @click="copyURL(mediaKit.short_direct_link, $gettext('Direct event link'))">
            <CIcon name="cipCopy" class="text-primary"/>
          </CButton>
        </div>
      </div>
      <div class="d-flex align-items-center mb-1" v-if="showDirectLink">
        <CInput :value="mediaKit.direct_link" readonly class="w-100 m-0"/>
        <div>
          <CButton color="dark" class="btn-circle ml-2" variant="outline" size="sm"
                   @click="copyURL(mediaKit.direct_link, $gettext('Direct event link'))">
            <CIcon name="cipCopy" class="text-primary"/>
          </CButton>
        </div>
      </div>

      <label class="small d-block mt-4" v-translate translate-context="event_wizard.overview">Message</label>
      <div class="d-flex align-items-start mb-1">
        <CTextarea :value="selectedSocialText" readonly class="w-100 m-0"/>
        <PDropDown class="ml-2" show-item-selector @change="onChangeLang" :items="langOptions"/>
        <div>
          <CButton color="dark" class="btn-circle ml-2" variant="outline" size="sm"
                   @click="copyURL(selectedSocialText, $gettext('Message'))">
            <CIcon name="cipCopy" class="text-primary"/>
          </CButton>
        </div>
      </div>
    </div>
  </div>
  </CCol>
</template>

<script>
import PDropDown from "@/domain/core/components/PDropDown.vue";
import PTooltip from "@/domain/core/components/PTooltip.vue";
import {tooltipEventWizard} from "@/domain/eventWizard/components/overview/tooltip";
import {tooltipColor} from "@/domain/eventMetric/tooltipContent";

export default {
  name: 'MediaKit',
  components: {PTooltip, PDropDown},

  props: {
    mediaKit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lang: 'en'
    }
  },
  computed: {
    langOptions() {
      return [
        {label: 'EN', value: 'en'},
        {label: 'IT', value: 'it'},
        {label: 'FR', value: 'fr'},
        {label: 'DE', value: 'de'},
      ]
    },
    selectedSocialText() {
      return this.mediaKit.social_text[this.lang]
    },
    tooltipColor() {
      return tooltipColor
    },
    showShortLink() {
      if (!this.mediaKit.short_direct_link) return false
      return this.mediaKit.short_direct_link
    },
    showDirectLink() {
      if (!this.mediaKit.short_direct_link) return true
      return this.hasPerm('event_wizard.see_raw_url')
    }
  },
  methods: {
    copyURL(url, description) {
      navigator.clipboard.writeText(url);
      this.notifyMessage({text: this.$gettext(description + ' copied!'), messageType: 'success'})
    },
    onChangeLang(lang) {
      this.lang = lang
    }
  }
}
</script>
