<template>
  <CModal :title="title" :show.sync="show" centered>
    <PFRow :label="$pgettext('event_wizard.folder_mapping.form','Folder path (es: promo/summer )')" size="12x12">
      <CInput v-model="form.folder"/>
    </PFRow>
    <PFRow :label="$pgettext('event_wizard.folder_mapping.form','Tags applied')" size="12x12">
      <PTreeTagInput v-model="form.tags" :allowed_tags="availableTags" :multiple="true"/>
    </PFRow>
    <template #footer>
      <CButton @click="show = false" color="secondary" v-translate>Cancel</CButton>
      <CButton @click="onSave" color="primary" :disabled="v$.$invalid" v-translate>Save</CButton>
    </template>
  </CModal>
</template>

<script>

import {useVuelidate} from "@vuelidate/core";

import 'vue2-timepicker/dist/VueTimepicker.css'
import PTagInput from "@/domain/core/components/PTagInput.vue";
import PFRow from "@/domain/core/components/PFRow.vue";
import {cloneDeep} from "lodash";
import {required,minLength} from "@vuelidate/validators";
import PTreeTagInput from "@/domain/core/components/PTreeTagInput.vue";

export default {
  name: "Editor",
  emits: ['save'],
  components: {PTreeTagInput, PFRow},
  data() {
    return {
      show: false,
      isNew: false,
      form: {
        folder: '',
        tags: [],
      },
    }
  },
  props: {
    availableTags: {
      type: Object,
      required: true
    },
  },
  computed: {
    title() {
      if (this.isNew)
        return this.$pgettext('event_wizard.folder_mapping.form.title', 'Add new folder mapping')
      else
        return this.$pgettext('event_wizard.folder_mapping.form.title', 'Edit folder mapping')
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      form: {
        folder: {required, minLength: minLength(3)},
        tags: {required}
      }
    }
  },
  methods: {
    open(data, isNew) {
      this.isNew = isNew
      this.form = cloneDeep(data)
      this.show = true
    },
    async onSave() {
      await this.emitPromised('save', this.form)
      this.show = false
    },
  }
}
</script>


<style scoped>

</style>