<template>
  <Summary
      :heading="setting.header"
      heading-icon="cipLogin"
      :palette="setting.palette"
      :tooltip="setting.tooltip"
      :heading-value="heading"
      :rows="rows"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Filters from "@/domain/core/utils/filters";
import {Settings} from "@/domain/stats/settings";
import Summary from "@/domain/stats/components/Summary.vue";

export default {
  name: "CheckinsSummary",
  components: {Summary},
  async mounted() {
    await this.fetchSummaryCheckins()
  },
  computed: {
    ...mapGetters('nStats', [
      'checkinsSummary'
    ]),
    setting() {
      return Settings.summary.checkins
    },
    heading() {
      if (!this.checkinsSummary) return
      return Filters.number(this.checkinsSummary.total)
    },
    rows() {
      if (this.checkinsSummary === null)
        return []
      return [
        {
          title: this.setting.qrcode.title,
          tooltip: this.setting.qrcode.tooltip,
          value: Filters.number(this.checkinsSummary.qrcode)
        },
        {
          title: this.setting.direct_link.title,
          tooltip: this.setting.direct_link.tooltip,
          value: Filters.number(this.checkinsSummary.link)
        }
      ]
    },
  },
  methods: {
    ...mapActions('nStats', [
      'fetchSummaryCheckins'
    ]),
  }
}
</script>