<template>
  <div>
    <CDropdown :caret="false"
               :offset="[0, 4]"
               :add-menu-classes="'styled-dd-menu'"
               class="styled-dd gallery-dd mb-3" v-if="lengthGalleries > 1">
      <template #toggler>
        <CButton block color="secondary" class="font-weight-normal pr-1">
          <div class="d-flex justify-content-between align-items-center">
            <div class="dd-text">
              <translate>Please select a gallery</translate>
            </div>
            <CIcon name="cipCaretDown" class="icon-sel"/>
          </div>
        </CButton>
      </template>
      <template v-for="(item, index) in galleries">
        <CDropdownItem :key="index" :class="{ 'disabled': item.passive }" @click="onClick(item)">
          <div class="d-flex justify-content-between w-100 align-items-center item"
               :class="{'price-list': item.missing_price_list && showMissingPriceList}">
            <div class="name">
              <CIcon size="custom-size" :height="15" :name="item.is_public?'cipFolderBlueStar':'cipFolderBlue'"
                     class="mr-2" style="vertical-align: baseline"/>
              {{ item.name }}
            </div>
            <div class="circle" v-if="!item.passive">
              <CIcon :name="icon" size="custom-size" :height="12"/>
            </div>
          </div>
        </CDropdownItem>
        <template v-for="(item, index) in item.sub">
          <CDropdownItem :key="'sub' + index" @click="onClick(item)">
            <div class="d-flex align-items-center" style="padding:8px">
              <CIcon size="custom-size" :height="16" name="cipCornerDownRight" class="ml-2" style="color:#A9A8C2"/>
            </div>
            <div class="d-flex justify-content-between w-100 align-items-center item"
                 :class="{'price-list': item.missing_price_list && showMissingPriceList}">
              <div class="name">
                <CIcon size="custom-size" :height="15" :name="item.is_public?'cipFolderBlueStar':'cipFolderBlue'"
                       class="mr-2" style="vertical-align: baseline"/>
                {{ item.name }}
              </div>
              <div class="circle">
                <CIcon :name="icon" size="custom-size" :height="12"/>
              </div>
            </div>
          </CDropdownItem>
        </template>
      </template>
    </CDropdown>
    <div class="dd-single" v-else>
      <CButton block color="secondary" :class="{'price-list': galleries[0].missing_price_list && showMissingPriceList}"
               class="font-weight-normal pr-1 mb-3" @click="onClick(galleries[0])">
        <div class="d-flex justify-content-between w-100 align-items-center item">
            <div class="name">
              <CIcon size="custom-size" :height="15" :name="galleries[0].is_public?'cipFolderBlueStar':'cipFolderBlue'"
                     class="mr-2" style="vertical-align: baseline"/>
              {{ galleries[0].name }}
            </div>
            <div class="circle">
              <CIcon :name="icon" size="custom-size" :height="12"/>
            </div>
          </div>
      </CButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "GalleriesDropDown",
  emits: ['click'],
  props: {
    galleries: {
      type: Array,
      required: true
    },
    icon: {
      type: String,
      default: 'cipUpload2'
    },
    showMissingPriceList: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lengthGalleries() {
      return this.galleries.reduce((acc, item) => acc + 1 + item.sub.length, 0)
    }
  },
  methods: {
    onClick(gallery) {
      this.$emit('click', gallery);
    }
  }
}
</script>

<style scoped>

</style>