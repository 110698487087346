import {translate} from "vue-gettext";

const {gettext: $gettext} = translate;

export default {
    single_day: 's',
    multi_day: 'm',
    multi_day_with_time: 't',
    options() {
        return [
            {
                id: this.single_day,
                name: $gettext('Single Day')
            },
            {
                id: this.multi_day,
                name: $gettext('Multi Day')
            },
            {
                id: this.multi_day_with_time,
                name: $gettext('Multi Day with Time')
            },
        ]
    }

}