<template>

  <CRow>
    <CCol sm="4">
      <div class="p-3 font-weight-bold">{{ name }}</div>
    </CCol>
    <CCol sm="4">
      <div class="p-3">{{ fieldTypeLabel }} ({{compactOptions}})</div>
    </CCol>
    <!-- click.stop prevents event bubbling -->
    <CCol sm="4" @click.stop="" style="cursor: default">
      <div class="p-3">
        <CInputCheckbox :custom="true" :checked.sync="mandatory"
                        :label="$pgettext('form_configurator','Field is mandatory')" @update:checked="save()"/>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import {fieldOptions} from "@/domain/formConfigurator/fields";

export default {
  name: "CompactSelectInput",
  props: {
    data: {
      type: Object,
      required: true
    },
    fieldType: {
      type: String,
      required: true
    }
  },
  computed: {
    fieldTypeLabel() {
      return fieldOptions.find(option => option.value === this.fieldType).label
    },
    compactOptions() {
      const options = this.options.map(option => option.label['it'])
      const joined = options.join(', ')
      if (joined.length > 10)
        return joined.substring(0, 15) + '...'
      return joined
    }
  },
  data() {
    return {
      name: '',
      options: [],
      mandatory: false,
    }
  },
  emits: ['save'],
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.name = this.data.name?.['it'] ?? ''
      this.options = this.data.params.options ?? []
      this.mandatory = this.data.mandatory ?? false
    },
    save() {
      this.$emit('save', {
        mandatory: this.mandatory,
      })
    }
  }
}
</script>