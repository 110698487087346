<template>
  <PIframe :src="frontEndUrl" :route="frontEndRoute" :data="data" />
</template>

<script>
import PIframe from "@/domain/core/components/PIframe.vue";

export default {
  name: "PreviewPopup",
  components: {PIframe},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
   computed: {
    frontEndUrl() {
      // TODO: Parametrizzare per domini terzo livello
      return process.env.VUE_APP_PICA_WEBSITE_FRONTEND_IFRAME + '?v=' + Math.random()
    },
    frontEndRoute() {
      return {page: 'preview.popup'}
    }
  },
}
</script>

<style scoped>

</style>