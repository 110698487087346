export const fieldTypes = {
    TEXT: 'text',
    SELECT: 'select',
    MULTY_SELECT: 'multySelect',
    PHONE: 'phone',
    EMAIL: 'email',
}

export const consentFieldType = 'consent'

export const fieldOptions = [
    {label: 'Telefono', value: fieldTypes.PHONE},
    {label: 'Email', value: fieldTypes.EMAIL},
    {label: 'Testo semplice', value: fieldTypes.TEXT},
    {label: 'Lista a scelta singola', value: fieldTypes.SELECT},
    {label: 'Lista a scelta multipla', value: fieldTypes.MULTY_SELECT},
]


export const consentTypes = {
    LINK: 'link',
    UPLOADED: 'uploaded',
    TEXT: 'text'
}

export const skipTypes = {
    NO_SKIP: '',
    SKIP_ICON: 'icon',
    SKIP_BUTTON: 'button'
}
export const skipOptions = [
    {label: 'Icona "X" in alto', input: skipTypes.SKIP_ICON},
    {label: 'Pulsante "No grazie"', input: skipTypes.SKIP_BUTTON},
    {label: 'Il form è obbligatorio', input: skipTypes.NO_SKIP},
]

export const elementRadiusOptions = [
    {iconName: 'cipRound0', value: 0},
    {iconName: 'cipRound1', value: 12},
    {iconName: 'cipRound2', value: 24},
]

export function generateKey(text) {
    if (text instanceof Object) text = text = text['it']
    if (!text || text.length < 1) return ''
    return text.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, '_')
}

export const defaultFields = [
    {
        order: 1,
        id: 1,
        type: fieldTypes.EMAIL,
        name: {it: 'Email', en: 'Email'},
        key: 'email',
        editable: false
    },
    {
        order: 2,
        id: 2,
        type: fieldTypes.TEXT,
        name: {it: 'Nome', en: 'First Name'},
        key: 'first_name',
        editable: true
    },
    {
        order: 3,
        id: 3,
        type: fieldTypes.TEXT,
        name: {it: 'Cognome', en: 'Last Name'},
        key: 'last_name',
        editable: true
    },
    {
        order: 4,
        id: 4,
        type: fieldTypes.PHONE,
        name: {it: 'Telefono', en: 'Phone'},
        key: 'phone',
        editable: true
    },
    {
        order: 5,
        id: 5,
        type: fieldTypes.TEXT,
        name: {it: 'Comune di residenza', en: 'City'},
        key: 'city',
        editable: true
    },
]

export const defaultConsents = []

export const generateRandomId = () => {
    return Math.random().toString(36).substring(7)
}