<template>
  <CModal :title="title" :show="true" @update:show="close" size="lg" centered>
    <CRow class="mb-3">
      <CCol col="12">
        <label v-translate translate-context="event_wizard.checkin_option">Name</label>
        <CInput
            v-model="v$.form.name.$model"
            ref="form-name">
          <template v-slot:invalid-feedback>
            <div class="invalid-feedback d-block" v-if="v$.form.name.$error">Name field has an error.</div>
          </template>
        </CInput>
      </CCol>
      <CCol col="12">
        <label>
          <translate translate-context="event_wizard.checkin_option.label">Subtitle</translate>
        </label>
        <CTextarea v-model="v$.form.description.$model"
                   ref="form-description"
        />
        <div class="invalid-feedback d-block" v-if="v$.form.image.$error" v-translate
             translate-context="event_wizard.checkin_option.error">Description has error
        </div>
      </CCol>

      <CCol col="12">
        <label v-translate translate-context="event_wizard.checkin_option">Option Tag</label>
        <PTreeTagInput v-model="form.tags" :allowed_tags="available_tags" :multiple="true"/>
      </CCol>
      <CCol col="12" class="mt-3">
        <label>
          <translate translate-context="event_wizard.checkin_option.label">Image</translate>
        </label>
        <PUploader
            :params="uploadParams.checkinOptionImage"
            :get_intent="get_image_upload_intent"
            :value.sync="v$.form.image.$model"
            :help_text="$pgettext('event_wizard.checkin_option.help_text','(Minimum size: 200x200 px - Recommended formats: .jpg .jpeg)')"
            @uploaded="newImageUploaded"
        />
        <div class="invalid-feedback d-block" v-if="v$.form.image.$error" v-translate
             translate-context="event_wizard.checkin_option.error">Image field has an error.
        </div>
      </CCol>
    </CRow>

    <template #footer>
      <CButton @click="close" color="secondary" v-translate>Cancel</CButton>
      <CButton @click="onSave" color="primary" :disabled="v$.$invalid" v-translate>Save</CButton>
    </template>
  </CModal>
</template>

<script>

import PUploader from "@/domain/core/components/PUploader.vue";
import {upload} from "@/domain/eventWizard/upload";
import {useVuelidate} from "@vuelidate/core";
import {cloneDeep} from "lodash";
import {DELETE_KEY} from "@/domain/core/components/uploader";
import {maxLength, minLength, required} from "@vuelidate/validators";
import PTreeTagInput from "@/domain/core/components/PTreeTagInput.vue";

export default {
  name: "EditCheckinOption",
  components: {PTreeTagInput, PUploader},
  data() {
    return {
      form: {
        name: null,
        tags: [],
        description: null,
        image: null,
      },
      image_url: null,
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    get_image_upload_intent: {
      type: Function,
      required: true
    },
    get_upload_intent_public_url: {
      type: Function,
      required: true,
    },
    available_tags: {
      type: Object,
      required: true,
    }
  },
  computed: {
    uploadParams() {
      return upload
    },
    isNewOption() {
      return this.data.isNew
    },
    title() {
      if (this.isNewOption)
        return this.$pgettext('event_wizard.checkin_option.modal', 'Add new option')
      else
        return this.$pgettext('event_wizard.checkin_option.modal', 'Edit option')
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  mounted() {
    this.onReset()
  },
  validations() {
    return {
      form: {
        name: {required, minLength: minLength(3), maxLength: maxLength(50)},
        tags: {required},
        description: {maxLength: maxLength(255)},
        image: {},
      }
    }
  },

  methods: {
    async onReset() {
      this.form = cloneDeep(this.data)
      this.image_url = ''
      if (this.form.image && this.form.image !== DELETE_KEY)
        this.image_url = this.form.image
    },

    async onSave() {
      await this.emitPromised('save', {
        ...this.form,
        image_url: this.image_url
      })
      this.close()
      await this.onReset()
    },

    async newImageUploaded() {
      await this.$nextTick()
      if (this.form.image === DELETE_KEY)
        this.image_url = ''
      else {
        this.image_url = await this.get_upload_intent_public_url(this.form.image)
      }
    },
    close() {
      this.$emit('cancel')
    }
  }
}
</script>