<template>
  <PIframe :src="frontEndUrl" :route="frontEndRoute" :data="data" v-if="isFormValid"/>
</template>

<script>

import PIframe from "@/domain/core/components/PIframe.vue";

export default {
  name: "PreviewForm",
  computed: {
    frontEndUrl() {
      return process.env.VUE_APP_PICA_FORM_FRONTEND_IFRAME
    },
    frontEndRoute() {
      return {page: 'form.preview'}
    },
    isFormValid() {
      return this.data && this.data.fields && this.data.fields.length > 0
    }
  },
  components: {PIframe},
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>