<template>
  <div>
    <CRow :gutters="false" class="align-items-end mb-2 stats-kpi" v-if="!loading && hasAnyPermission" data-v-step="3">
      <CCol>
        <CRow>
          <CCol sm="6" :xl="colXl" class="mb-2" v-if="hasPerm('stats.orders.summary')">
            <OrdersSummary/>
          </CCol>
          <CCol sm="6" :xl="colXl" class="mb-2" v-if="hasPerm('stats.checkins.summary')">
            <CheckinsSummary/>
          </CCol>
          <CCol sm="6" :xl="colXl" class="mb-2" v-if="hasPerm('stats.media.summary')">
            <MediaSummary/>
          </CCol>
          <CCol sm="6" :xl="colXl" class="mb-2" v-if="hasPerm('stats.engagement.summary')">
            <EngagementSummary/>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="auto" v-if="hasPerm('dashboard.view_feature_stats')" class="d-none d-sm-block">
        <CButton color="dark"
                 variant="outline"
                 class="btn-circle btn-circle--sm mb-2 ml-3"
                 size="sm"
                 style="background: white"
                 :to="routeToStats()">
          <CIcon name="cipArrowNarrowUpRight"/>
        </CButton>
      </CCol>
    </CRow>
    <hr class="mb-4" v-if="hasAnyPermission">
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import OrdersSummary from "@/domain/stats/summary/OrdersSummary.vue";
import CheckinsSummary from "@/domain/stats/summary/CheckinsSummary.vue";
import EngagementSummary from "@/domain/stats/summary/EngagementSummary.vue";
import MediaSummary from "@/domain/stats/summary/MediaSummary.vue";

export default {
  name: 'Stats',
  components: {MediaSummary, EngagementSummary, CheckinsSummary, OrdersSummary},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    this.loading = true
    await this.fetchConfig({type: 'stats', id: this.picaServiceId})
    this.loading = false
  }, computed: {
    hasAnyPermission() {
      if (this.loading) return false
      return this.hasPerm('stats.orders.summary') ||
          this.hasPerm('stats.checkins.summary') ||
          this.hasPerm('stats.media.summary') ||
          this.hasPerm('stats.engagement.summary')
    },
    ...mapGetters('event', [
      'picaServiceId',
    ]), numberOfStats() {
      return [
        this.hasPerm('stats.orders.summary'),
        this.hasPerm('stats.checkins.summary'),
        this.hasPerm('stats.media.summary'),
        this.hasPerm('stats.engagement.summary')
      ].filter(Boolean).length
    }, colXl() {
      return 12 / this.numberOfStats
    }
  },
  methods: {
    ...mapActions('login', [
      'fetchConfig'
    ]),
    routeToStats() {
      return {name: 'stats', params: {picaServiceId: this.picaServiceId}}
    }
  },
}
</script>
