<template>
  <div class="p-uploader__no-image">
    <div v-if="!disabled"
         class="dropzone-container"
         @dragover="dragover"
         @dragleave="dragleave"
         @drop="drop"
    >
      <input
          type="file"
          :multiple="multiple"
          name="file"
          id="fileInput"
          class="hidden-input"
          ref="file"
          @change="onChange"
      />
      <label for="fileInput" class="file-label text-center">
        <span v-if="isDragging" v-translate>Release to drop files here.</span>
        <template v-else>
          <span>
            <CIcon class="text-primary d-block mx-auto mb-2" size="custom-size" :height="45" name="cipUploadCloud"/>
            <span v-translate>Drag the file here or</span> <span class="text-primary font-weight-semi-bold" v-translate>click to upload</span>
          </span>
        </template>
      </label>
    </div>
  </div>
</template>

<script>
import fileUtils from "@/domain/core/utils/fileUtils";

export default {
  name: "PFileDrop",
  emit: ['dropped'],
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDragging: false,
      cbOnFileSelected: null
    }
  },
  methods: {
    onChange() {
      if (this.multiple)
        this.$emit('dropped', this.$refs.file.files)
      else
        this.$emit('dropped', this.$refs.file.files[0])
      if (this.cbOnFileSelected) {
        this.cbOnFileSelected(this.$refs.file.files)
        this.cbOnFileSelected = null
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave(e) {
      // Get the bounding rectangle of the drop zone
      const dropzoneRect = e.currentTarget.getBoundingClientRect();

      // Check if the mouse coordinates are outside the drop zone
      if (
          e.clientX < dropzoneRect.left ||
          e.clientX > dropzoneRect.right ||
          e.clientY < dropzoneRect.top ||
          e.clientY > dropzoneRect.bottom
      ) {
        this.isDragging = false;
      }
    },
    async drop(e) {
      e.preventDefault();
      const files = await fileUtils.getFilesFromDropEvent(e)
      if (this.multiple)
        this.$emit('dropped', files)
      else
        this.$emit('dropped', files[0])
      this.isDragging = false;
    },
    open(cbOnFileSelected) {
      this.$refs.file.click()
      if (cbOnFileSelected !== undefined)
        this.cbOnFileSelected = cbOnFileSelected
    }
  },

}
</script>

<style scoped>

</style>