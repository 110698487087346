import {generateRandomId} from "@/domain/formConfigurator/fields";
import {cloneDeep} from "lodash";

export const methods = {
    init() {
        this.rows = this.data ?? []
    }, addRow() {
        this.showEditor = true
        this.row = null
    }, saveFromModal(data) {
        if (!data.id) {
            data.id = generateRandomId()
            data.order = this.rows.length + 1
            this.rows.push(data)
        } else {
            this.updateRow(data.id, data)
        }
        this.emitUpdateData()
        this.showEditor = false
    }, deleteRow(event,id) {
        event.preventDefault()
        this.rows = this.rows.filter((input) => {
            return input.id !== id
        })
        this.emitUpdateData()
    }, updateRow(id, data) {
        this.rows = this.rows.map((row) => {
            if (row.id !== id) return row
            return {
                ...row,
                ...data
            }
        })
        this.emitUpdateData()
    }, updateSorting() {
        this.rows = this.rows.map((input, index) => {
            input.order = index + 1
            return input
        })
        this.emitUpdateData()
    }, editRow(event, row) {
        event.preventDefault()
        if (!row.editable) return
        this.row = cloneDeep(row)
        this.showEditor = true
    }, emitUpdateData() {
        this.$emit('update:data', this.rows)
        this.$emit('preview', this.rows)
    }
}