<template>
  <PTransparentLoader class="event-wizard" :loading="loading">
    <CContainer class="p-0">
      <h1 class="main-header mb-1" v-translate translate-context="event_wizard">Check-in options</h1>
      <p v-translate translate-context="event_wizard.subtitle">
        Personalize the participant experience entering the event even more with multiple check-in options of your
        choice</p>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Title')"
             separator
             label-class="big">
        <CInput
            v-model="question"
            :placeholder="$pgettext('event_wizard.form','Insert title...')"
            :label="$pgettext('event_wizard.label','What do you want to ask to your guests?')"
            @keyup="previewFieldUpdated"
        >
          <template #label-after-input>
            <div class="text-help mt-1">
              <translate translate-context="event_wizard.help">
                (Example: “Choose your team” - “Which test will you participate in?”)
              </translate>
            </div>
          </template>
        </CInput>
      </PFRow>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Options')"
             :help-text="$pgettext('event_wizard.help', 'Create one or more choices for the user<')"
             separator
             label-class="big">
        <div>
          <CLink class="form-generator__add-field" @click="addOption">
            <CIcon class="text-primary mr-1" size="xl" name="cipPlusCircle"/>
            <span v-translate>Add option</span>
          </CLink>
        </div>
        <div>
          <draggable v-model="options" handle=".handle" ghost-class="ghost" @end="previewFieldUpdated">
            <transition-group type="transition" :name="'flip-list'">
              <div class="form-generator__field" v-for="option in options" :key="option.id">
                <div class="flex-grow-1 mr-4 cursor-pointer" @click="editOption(option)">
                  <CheckinOption :data="option"/>
                </div>
                <div class="handle p-3">
                  <CIcon size="lg" name="cip-hamburger"/>
                </div>
                <div class="mr-3">
                  <CButtonClose class="list-item-closer" @click="deleteOption(option)">
                    <CIcon name="cipClose" size="custom-size" width="15"/>
                  </CButtonClose>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </PFRow>
    </CContainer>

    <div class="event-wizard__footer">
      <CRow>
        <CCol>
          <div class="d-flex justify-content-end">
            <div class="mr-2">
              <CButton color="secondary" block @click="onReset" ref="reset-button"
                       v-translate translate-context="event_wizard">
                Reset
              </CButton>
            </div>
            <div>
              <CButton color="primary" @click="onSave" block ref="save-button"
                       v-translate translate-context="event_wizard">
                Save
              </CButton>
            </div>

          </div>
        </CCol>
      </CRow>
    </div>

    <EditCheckinOption
        v-if="showEditor"
        :available_tags="checkinOptionsTags"
        :get_upload_intent_public_url="get_upload_intent_public_url"
        :get_image_upload_intent="get_image_upload_intent"
        :data="option"
        @updatePreview="previewFieldUpdated"
        @save="saveOption"
        @cancel="showEditor = false"
    />

  </PTransparentLoader>
</template>

<script>
import {v4 as uuid} from 'uuid';
import CheckinOption from "@/domain/eventWizard/components/CheckinOption.vue";
import draggable from 'vuedraggable'
import {cloneDeep} from "lodash";
import EditCheckinOption from "@/domain/eventWizard/components/EditCheckinOption.vue";
import PTransparentLoader from "@/domain/core/components/PTransparentLoader.vue";
import {mapActions, mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";

export default {
  name: "StepCheckinOptions",
  components: {PFRow, PTransparentLoader, EditCheckinOption, draggable, CheckinOption},
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    get_image_upload_intent: {
      type: Function,
      required: true,
    },
    get_upload_intent_public_url: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters('eventWizard', ['checkinOptionsTags']),
  },
  data() {
    return {
      options: [],
      question: null,
      showEditor: false,
      option: null,
    }
  },
  emits: ['save', 'updatePreview'],
  async mounted() {
    await this.fetchCheckinOptionsTags()
    this.onReset()
  },

  methods: {
    ...mapActions('eventWizard', ['fetchCheckinOptionsTags']),
    onReset() {
      this.question = this.data.question
      if (!this.data.options) this.data.options = []
      this.options = cloneDeep(this.data.options).sort((a, b) => a.order - b.order)

      this.previewFieldUpdated()
    },

    async onSave() {
      this.updateSorting()
      await this.emitPromised('save', {
        question: this.question,
        options: this.options.map(option => {
          // The id is valid and should be kept only if it's a "valid" id, and not a "fake" uuid used only for differentiate element in the loop
          return {
            ...option,
            id: option.isNew ? null : option.id,
          }
        })
      })
      this.onReset()
    },

    previewFieldUpdated() {
      this.$emit('updatePreview', {
        options: this.options.filter(o => o.name && o.tags),
        question: this.question
      })
    },

    addOption() {
      this.option = {
        id: uuid(),
        name: null,
        tags: [],
        description: null,
        image: null,
        image_url: null,
        isNew: true,
        order: this.options.length + 1,
      }
      this.showEditor = true
    },

    saveOption(option) {
      if (option.isNew) {
        this.options.push(option)
      } else {
        this.options = this.options.map(o => {
          if (o.id === option.id)
            return option
          return o
        })
      }
      this.previewFieldUpdated()
    },
    editOption(option) {
      this.showEditor = true
      this.option = option
    },
    deleteOption(option) {
      this.options = this.options.filter(o => o.id !== option.id)
      this.previewFieldUpdated()
    },

    updateSorting() {
      let sort = 1
      this.options = this.options.map((option) => {
        option.order = sort++
        return option
      })
    },
  }
}
</script>

<style scoped>

</style>