<template>
  <div class="form-generator">
    <draggable v-model="rows" @end="updateSorting" handle=".handle" ghost-class="ghost">
      <transition-group type="transition" :name="'flip-list'">
        <div v-for="input in rows" :key="input.id" :class="['form-generator__field']">
          <div class="flex-grow-1 mr-4" @click="editRow($event,input)" :class="[{'editable': input.editable}]">
            <CompactTextInput :data="input"
                              :field-type="input.type"
                              v-if="[fieldTypes.TEXT,fieldTypes.PHONE,fieldTypes.EMAIL].includes(input.type)"
                              @save="updateRow(input.id,$event)"/>
            <CompactSelectInput :data="input"
                                :field-type="input.type"
                                v-if="[fieldTypes.SELECT,fieldTypes.MULTY_SELECT].includes(input.type)"
                                @save="updateRow(input.id,$event)"/>
          </div>
          <div class="handle p-3">
            <CIcon size="lg" name="cip-hamburger"/>
          </div>
          <div class="mr-3">
             <CButtonClose  class="list-item-closer" @click="deleteRow($event,input.id)" v-if="input.deletable">
               <CIcon name="cipClose" size="custom-size" width="15" />
             </CButtonClose>
          </div>

        </div>
      </transition-group>
    </draggable>

    <CLink class="form-generator__add-field" @click="addRow">
      <CIcon class="text-primary mr-1" size="xl" name="cipPlusCircle"/>
      <span v-translate><b>Add</b> field</span>
    </CLink>
    <EditField
        v-if="showEditor"
        :data="row"
        :advanced="advanced"
        :langs="langs"
        @save="saveFromModal"
        @cancel="showEditor = false"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {fieldTypes} from "@/domain/formConfigurator/fields";
import CompactTextInput from "@/domain/formConfigurator/compact/CompactTextInput.vue";
import CompactSelectInput from "@/domain/formConfigurator/compact/CompactSelectInput.vue";
import EditField from "@/domain/formConfigurator/component/EditField.vue";
import {methods as rows_methods} from "@/domain/formConfigurator/component/rows";


export default {
  name: "Fields",
  computed: {
    fieldTypes() {
      return fieldTypes
    }
  },
  emits: ['update:data', 'preview'],
  components: {EditField, CompactSelectInput, CompactTextInput, draggable},
  props: {
    data: {
      type: Array,
      required: true
    },
    advanced: {
      type: Boolean,
      required: true
    },
    langs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showEditor: false,
      rows: [],
      row: {},
    }
  },
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...rows_methods
  }
}
</script>