<template>
  <div class="kpi" :class="['kpi--' + palette.name]">
    <ViewLoadingMessage extra-class="mt-5" :loading="loading" :size="`sm`"/>
    <div v-if="!loading">
      <div class="kpi__head">
        <div class="d-flex">
          <div>
            <CIcon :name="headingIcon" width="40" size="custom-size" class="mr-2 icon-circle" :style="textColor"/>
          </div>
          <div>
            <div class="d-flex">
              <h6 class="m-0">{{ heading }}</h6>
              <PTooltip class="ml-1" v-if="tooltip" :content="tooltip" :color="tooltipColor"
                        :placement="tooltipPlacement"/>
            </div>
            <div class="value" :style="textColor">{{ headingValue }}</div>
          </div>
        </div>
      </div>
      <div>
        <CListGroup flush>
          <CListGroupItem
              v-for="row in rows"
              :key="row.title"
              class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0"
          >
            <div class="d-flex w-100 justify-content-between">
              <div class="d-flex">
                <span>{{ row.title }}</span>
                <PTooltip :content="row.tooltip" placement="right" class="ml-1" :color="tooltipColor"
                          v-if="row.tooltip"/>
              </div>

              <span class="font-weight-semi-bold">{{ row.value }}</span>
            </div>
          </CListGroupItem>
        </CListGroup>
      </div>
    </div>
  </div>
</template>

<script>
import PTooltip from "@/domain/core/components/PTooltip.vue";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import {tooltipColor} from "@/domain/eventMetric/tooltipContent";

export default {
  name: "Summary",
  components: {ViewLoadingMessage, PTooltip},
  props: {
    rows: {
      type: Array,
      required: true,
    },
    palette: {
      type: Object,
      required: this,
    },
    heading: {
      type: String,
      required: false
    },
    headingIcon: {
      type: String,
      required: false
    },
    headingValue: {
      type: [String, Number],
      required: false
    },
    tooltip: {
      type: String,
      required: false
    },
    tooltipPlacement: {
      type: String,
      required: false,
      default: 'top'
    },
  },
  computed: {
    tooltipColor() {
      return tooltipColor
    },
    textColor() {
      return {
        color: this.palette.color
      }
    },

    loading() {
      return this.rows && this.rows.length === 0
    },
  },
}
</script>