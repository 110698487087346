<template>
  <div>
    <img
        v-if="data && data.sample_image"
        :src="data.sample_image"
        height="60px"
        style="cursor: pointer"
        v-c-popover="{header: `${ data.name }`,
                                     content: `<div class='text-center'><img src='${ data.sample_image }' style='height: 200px' /></div>`,
                                     placement: 'right-end'}"
    />
    <template v-else>
      -
    </template>
  </div>
</template>

<script>
export default {
  name: "LayoutPreview",
  props: {
    data: {
      type: [Object, null],
      required: false
    }
  }
}
</script>
