<template>
  <div>
    <div v-for="category in categories" :key="category" class="mb-2 p-2 border" style="border-radius: 16px">
      <div class="font-weight-bold mb-1">{{ category }}</div>
      <div>
      <CBadge v-for="tag in tags[category]" :key="tag" shape="pill" color="primary-light no-delete mr-2 mb-2">
        {{ tag }}
      </CBadge>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TagSummary",
  props: {
    tags: {
      type: Object,
      required: true
    }
  },
  computed: {
    categories() {
      return Object.keys(this.tags)
    }
  }
}
</script>

<style scoped>

</style>