<template>
  <div>
    <div v-if="failed">
      <slot name="failed"/>
    </div>
    <div v-else-if="completed">
      <slot name="completed"/>
    </div>
    <div v-else-if="inProgress">
      <slot name="in-progress"/>
      <CProgress :value="current" :max="total" color="success" show-percentage v-if="!completed"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskStatus",
  props: {
    data: {
      type: [Object, null],
      required: false
    }
  },
  computed: {
    completed() {
      if (!this.data)
        return true
      return this.data.completed
    },
    failed() {
      return this.data && this.completed && this.data.error
    },
    inProgress() {
      return this.data && !this.completed && !this.failed
    },
    current() {
      return this.data?.info?.current || 0
    },
    total() {
      return this.data?.info?.total || 1
    }
  }
}
</script>
