<template>
  <div>
    <div class="p-3 d-flex align-items-center">
      <div class="mr-3 flex-grow-1">
        <label class="mb-1">{{ data.folder }}</label>
      </div>
      <div class="flex-grow-1">
        <CBadge v-for="tag in data.tags" :key="tag" shape="pill" color="primary-light no-delete mr-2 mb-1">
          {{ tag }}
        </CBadge>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Detail",
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
