<template>
  <Summary
      :heading="setting.header"
      heading-icon="cipCreditCard02"
      :palette="setting.palette"
      :tooltip="setting.tooltip"
      :heading-value="heading"
      :rows="rows"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Filters from "@/domain/core/utils/filters";
import {Settings} from "@/domain/stats/settings";
import Summary from "@/domain/stats/components/Summary.vue";

export default {
  name: "OrdersSummary",
  components: {Summary},
  async mounted() {
    await this.fetchOrdersSummary()
  },
  computed: {
    ...mapGetters('nStats', [
      'ordersSummary'
    ]),
    ...mapGetters('event', [
      'eventCurrency'
    ]),
    setting() {
      return Settings.summary.orders
    },
    heading() {
      if (!this.ordersSummary) return
      return Filters.currency(this.ordersSummary.amount, this.eventCurrency)
    },
    rows() {
      if (this.ordersSummary === null)
        return []
      return [
        {
          title: this.setting.qty.title,
          tooltip: this.setting.qty.tooltip,
          value: Filters.number(this.ordersSummary.qty)
        },
        {
          title: this.setting.conversion_rate.title,
          tooltip: this.setting.conversion_rate.tooltip,
          value: Filters.percent(this.ordersSummary.buyers,this.ordersSummary.checkins),
        }
      ]
    },
  },
  methods: {
    ...mapActions('nStats', [
      'fetchOrdersSummary'
    ]),
  }
}
</script>