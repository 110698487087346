<template>
  <CModal :show.sync="show" centered size="xl">
    <div class="form-row form-group">
      <CCol>
        <CAlert color="warning" :fade="false" v-show="!layout">
          <translate>Select a layout</translate>
        </CAlert>
        <CRow>
          <CCol sm="3">
            <translate>Format</translate>
          </CCol>
          <CCol sm="9">
            <CSelect v-model="format" :options="layoutFormats"/>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6" v-for="option in layoutOptions" :key="option.id">
            <LabelLayoutItem :item="option" :selected="layout === option.id" @select="layout = option.id"/>
          </CCol>
        </CRow>
      </CCol>
    </div>
    <template #footer>
      <CButton color="secondary" size="sm" @click="show = false">Close</CButton>
      <CButton color="primary" size="sm" @click="onGenerate" v-translate
               translate-context="pica_code.modal.label_generation">
        Generate
      </CButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import layoutFormats from "@/domain/core/constant/layoutFormats";
import LabelLayoutItem from "@/domain/picaCode/components/LabelLayoutItem.vue";

export default {
  name: "LabelsGenerationModal",
  components: {LabelLayoutItem},
  emits: ['generate'],
  computed: {
    layoutFormats() {
      return layoutFormats.options().map(option => ({value: option.id, label: option.name}))
    },
    ...mapGetters('picaCode', ['layoutOptions'])
  },
  data() {
    return {
      show: false,
      layout: null,
      format: layoutFormats.getDefault(),
    }
  },
  methods: {
    ...mapActions('picaCode', ['fetchLayoutOptions', 'generateLabels']),
    async open(ids) {
      this.show = true
      this.ids = ids
      this.layout = null
      this.format = layoutFormats.getDefault()
      await this.fetchLayoutOptions()
    },
    async onGenerate() {
      if (!this.layout) {
        await this.notifyError(this.$pgettext('pica_code.modal.label_generation', 'Select a layout for the generation'))
        return
      }
      await this.generateLabels({
        ids: this.ids,
        layout: this.layout,
        format: this.format
      })
      this.$emit('generate')
      this.show = false
    },
  }
}
</script>
