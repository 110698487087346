<template>
  <CCol md="6" xl="4" class="mb-3">
    <div class="overview-widget" data-v-step="2">
      <QrCodeModal ref="qrCodeModal">
        <div class="sub-img qr-code qr-code-lg" :style="{backgroundColor: background}">
          <qrcode-vue v-bind="QRCodeProps" ref="qrcode"/>
        </div>
      </QrCodeModal>
      <div class="overview-widget__head">
        <div class="d-flex align-items-center">
          <h5 class="m-0" v-translate>QR Code</h5>
          <PTooltip class="ml-2"
                    :content="$pgettext('overview.tooltip', 'Display the QR code and guests will be able to access their photos by scanning it.')"
                    :color="tooltipColor" placement="top"/>
        </div>
      </div>
      <div class="overview-widget__img">
        <CImg :src="require('@/assets/images/overview/qr.png')" fluid block class="w-100"/>
        <CLink @click="$refs.qrCodeModal.open()">
          <div class="sub-img qr-code" :style="{backgroundColor: background}">
            <qrcode-vue v-bind="QRCodeProps" ref="qrcode"/>
          </div>
        </CLink>
      </div>
      <div class="overview-widget__body">
        <CRow>
          <CCol lg="6">
            <label class="small" v-translate translate-context="qrcode.label">Background color</label>
            <PColorPicker :color="background"
                          disable-alpha
                          block
                          :extra-classes="'d-flex'"
                          @change="color => background = color.hex"/>
          </CCol>
          <CCol lg="6">
            <label class="small" v-translate translate-context="qrcode.label">Foreground color</label>
            <PColorPicker :color="foreground"
                          disable-alpha
                          block
                          @change="color => foreground = color.hex"/>
          </CCol>
          <CCol col="12">
            <div>
              <label v-translate translate-context="qrcode.label" class="d-block small">Download file</label>
              <CRow :gutters="false">
                <CCol>
                  <CButton block @click="download('canvas', 'png')" size="sm" color="secondary">
                    <CIcon class="text-primary" name="cipDownload03"/>
                    .png
                  </CButton>
                </CCol>
                <CCol class="mx-2">
                  <CButton block @click="download('canvas', 'jpg')" size="sm" color="secondary">
                    <CIcon class="text-primary" name="cipDownload03"/>
                    .jpg
                  </CButton>
                </CCol>
                <CCol>
                  <CButton block @click="download('svg', 'svg')" size="sm" color="secondary">
                    <CIcon class="text-primary" name="cipDownload03"/>
                    .svg
                  </CButton>
                </CCol>
              </CRow>
            </div>
          </CCol>
        </CRow>
        <template v-if="showQrLink">
          <CRow class="mt-3">
            <CCol>
              <label class="small d-block" v-translate translate-context="event_wizard.overview">QR Code link</label>
              <div class="d-flex align-items-center mb-1" v-if="short">
                <CInput :value="short" readonly class="w-100 m-0"/>
                <div>
                  <CButton color="dark" class="btn-circle ml-2" variant="outline" size="sm"
                           @click="copyURL(short, $gettext('Short QR code link'))">
                    <CIcon name="cipCopy" class="text-primary"/>
                  </CButton>
                </div>
              </div>
              <div class="d-flex align-items-center mb-1" v-if="direct">
                <CInput :value="direct" readonly class="w-100 m-0"/>
                <div>
                  <CButton color="dark" class="btn-circle ml-2" variant="outline" size="sm"
                           @click="copyURL(direct, $gettext('Direct QRCode link'))">
                    <CIcon name="cipCopy" class="text-primary"/>
                  </CButton>
                </div>
              </div>
            </CCol>
          </CRow>
        </template>
      </div>
    </div>
  </CCol>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import {downloader} from "@/domain/core/components/qrcode/downloader";
import PColorPicker from "@/domain/core/components/PColorPicker.vue";
import {tooltipColor} from "@/domain/eventWizard/components/overview/tooltip";
import PTooltip from "@/domain/core/components/PTooltip.vue";
import QrCodeModal from "@/domain/core/components/qrcode/QrCodeModal.vue";

export default {
  name: "QRCodeGenerator",
  components: {QrCodeModal, PTooltip, PColorPicker, QrcodeVue},
  props: {
    value: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      default: 'QRCode'
    },
    direct: {
      type: String,
      required: false
    },
    short: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      size: 1000,
      renderAs: 'canvas',
      background: '#ffffff',
      foreground: '#000000'
    }
  },
  computed: {
    tooltipColor() {
      return tooltipColor
    },
    QRCodeProps() {
      return {
        value: this.value,
        size: this.size,
        renderAs: this.renderAs,
        background: this.background,
        foreground: this.foreground
      }
    },
    showQrLink() {
      return this.hasPerm('event_wizard.see_raw_url')
    }
  },
  methods: {
    async download(renderAs, exportType) {
      this.renderAs = renderAs
      await this.$nextTick()
      if (this.renderAs === 'svg') {
        const qrcode = this.$refs.qrcode.$el.querySelector('svg')
        downloader.fromSvg(qrcode, this.fileName + '.svg')
      } else {
        if (exportType === 'jpg') {
          const qrcode = this.$refs.qrcode.$el.querySelector('canvas')
          downloader.fromJpegCanvas(qrcode, this.fileName + '.jpg', {padding: 40, backgroundColor: this.background})
        } else if (exportType === 'png') {
          const qrcode = this.$refs.qrcode.$el.querySelector('canvas')
          downloader.fromPngCanvas(qrcode, this.fileName + '.png', {padding: 40, backgroundColor: this.background})
        }
      }
    },
    copyURL(url, description) {
      navigator.clipboard.writeText(url);
      this.notifyMessage({text: this.$gettext(description + ' copied!'), messageType: 'success'})
    },
  },
}
</script>