<template>
  <CModal :title="title" :show="true" @update:show="close" size="lg">
      <FormConsentInput :get_consent_upload_intent="get_consent_upload_intent" :data.sync="field" :advanced="advanced" :langs="langs" />
      <template #footer>
        <CButton @click="close" color="secondary" v-translate>Cancel</CButton>
        <CButton @click="save" color="primary" v-translate>Save</CButton>
      </template>
    </CModal>
</template>

<script>
import FormConsentInput from "@/domain/formConfigurator/Input/FormConsentInput.vue";
import {consentFieldType} from "@/domain/formConfigurator/fields";

const emptyForm = {
  type: consentFieldType,
  editable: true,
}

export default {
  name: "EditConsent",
  emits: ['save','cancel'],
  components: {FormConsentInput },
  props: {
    data: {
      type: Object,
      default: null
    },
    get_consent_upload_intent: {
      type: Function,
      required: true
    },
    advanced: {
      type: Boolean,
      required: true
    },
    langs: {
      type: Array,
      required: true
    }
  },
  computed: {
    title() {
      if (this.isNewField)
        return this.$pgettext('form_configurator.modal','Add new consent')
      else
        return this.$pgettext('form_configurator.modal','Edit consent')
    }
  },
  data() {
    return {
      field: {...emptyForm},
    }
  },
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.field = this.data ?? {...emptyForm}
    },
    save() {
      this.$emit('save', this.field)
    },
    close() {
      this.$emit('cancel')
    }
  }
}
</script>