<template>
  <v-tour :name="name" :steps="steps" :options="options" :callbacks="callbacks">
    <template v-slot:default="tour">
      <transition name="fade">
        <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="tour.highlight"
        >
          <div slot="actions">
            <div class="d-flex justify-content-between align-items-center v-tour__actions">
              <CLink @click="tour.skip" v-translate>Skip</CLink>
              <div class="d-flex align-items-center">
                <div class="mr-3">
                  <span class="counter">{{ tour.currentStep + 1 }} <translate>of</translate> {{
                      tour.steps.length
                    }}</span>
                </div>
                <CButton @click="tour.nextStep" color="primary" v-if="!tour.isLast">Next</CButton>
                <CButton @click="tour.finish" color="primary" v-if="tour.isLast">Continue
                </CButton>
              </div>
            </div>
          </div>
        </v-step>
      </transition>
    </template>
  </v-tour>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: "PTour",
  data() {
    return {
      startTimeout: 0,
      callbacks: {
        onStart: this.onStart,
        onSkip: this.onSkip,
        onFinish: this.onFinish
      }
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    steps: {
      type: Array,
      required: true
    }
  },
  computed: {
    options() {
      return {
        startTimeout: this.startTimeout,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10]
            }
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('tours', ['setTourViewed']),
    start(step = '0', timeout = 0) {
      this.startTimeout = timeout
      this.$tours[this.name].start(step)
      this.setTourViewed(this.name)
    },
    stop() {
      this.$tours[this.name].stop()
    },
    onSkip() {
    },
    onFinish() {
    },
    async onStart() {
      await this.$nextTick()
      window.addEventListener('click', (e) => {
        const target = e.target
        if (target.tagName === 'A' || target.tagName === 'BUTTON' ) return
        this.removeClickEvent()
        this.stop()
      })
    },
    removeClickEvent() {
      window.removeEventListener('click', () => {
        this.stop()
      })
    }
  },
}
</script>

