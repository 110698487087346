<template>
  <div>
    <CContainer>

      <div class="grid-stripped">
        <PFRow :label="$pgettext('event_template.label', 'Promo')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" disabled :checked.sync="form.is_promo"/>
        </PFRow>
        <CRow class="mb-4">
          <CCol>
            Se il numero di "incluse" è vuoto, significa che è illimitato, 0 implica nessun caricamento possibile<br>
            Se extra block size è vuoto o il suo prezzo è vuoto, impedisce il caricamento oltre il limite
          </CCol>
        </CRow>
        <CRow>
          <CCol><h5 v-translate translate-context="plan.section">Photo</h5></CCol>
        </CRow>
        <div class="grid-stripped mb-3">
          <PFRow :label="$pgettext('plan.label','Included')">
            <CInput v-model="form.num_photo_included" type="number"/>
          </PFRow>
          <PFRow :label="$pgettext('plan.label','Price for extra media')">
            <CInput v-model="form.extra_photo_price" type="number"/>
          </PFRow>
          <PFRow :label="$pgettext('plan.label','Max uploadable media')">
            <CInput v-model="form.num_max_photo" type="number"/>
          </PFRow>
        </div>
        <CRow>
          <CCol><h5 v-translate translate-context="plan.section">Participants</h5></CCol>
        </CRow>
        <div class="grid-stripped mb-3">
          <PFRow :label="$pgettext('plan.label','Max participants for welcome code')">
            <CInput v-model="form.max_participants" type="number"/>
          </PFRow>
        </div>
        <CRow>
          <CCol><h5 v-translate translate-context="plan.section">Fee / Perc</h5></CCol>
        </CRow>
        <div class="grid-stripped mb-3">
          <PFRow label="Pica fee perc"
                 :helpText="$pgettext('plan.label','Add a fee (as a percentage, like 20) to the event\'s sales if \'Pica fee perc during stay\' is not set or if the payment is not made during the stay.')">
            <CInput v-model="form.pica_fee_perc" type="number"/>
          </PFRow>
          <PFRow label="Pica fee perc during stay"
                 :helpText="$pgettext('plan.label','Add a fee percentage for sales during the event stay. If this field is left empty or if the payment is not made during the stay, the general \'Pica fee perc\' will be applied.')">
            <CInput v-model="form.pica_fee_perc_during_stay" type="number"/>
          </PFRow>
          <PFRow label="Additional fee perc"
                 :helpText="$pgettext('plan.label','Additional fee (in percentage) to be added to the sales of the event.  Not expressed as decimal, but as percentage (e.g. 5)')">
            <CInput v-model="form.additional_fee_perc" type="number"/>
          </PFRow>
          <PFRow label="Revenue Photographer percent after pica"
                 :helpText="$pgettext('plan.label','Percentuale all\'agenzia fotografica al netto di pica e costi')">
            <CInput v-model="form.revenue_photographer_percent_after_pica" type="number"/>
          </PFRow>
          <PFRow label="Revenue Organizer percent after pica"
                 :helpText="$pgettext('plan.label','Percentuale all\'organizzatore al netto di pica e costi')">
            <CInput v-model="form.revenue_organizer_percent_after_pica" type="number"/>
          </PFRow>
          <PFRow label="Handling fee"
                 :helpText="$pgettext('plan.label','Fixed fee (in currency) to be added to each order')">
            <CInput v-model="form.handling_fee" type="number"/>
          </PFRow>
          <PFRow label="Assign transaction costs to pica"
                 :helpText="$pgettext('plan.label','Enable if the transaction costs are paid by Pica')">
            <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                     :checked.sync="form.assigns_transaction_costs_to_pica"/>
          </PFRow>
        </div>
      </div>
    </CContainer>
    <div class="d-flex justify-content-end p-3 border-top">
      <CButton color="success" variant="outline" @click="onReset" ref="reset-button" class="mr-2"
               v-translate translate-context="event_wizard">
        Reset
      </CButton>
      <CButton color="primary" @click="onSave" ref="save-button"
               v-translate translate-context="event_wizard">
        Save
      </CButton>
    </div>
  </div>
</template>

<script>
import PFRow from "@/domain/core/components/PFRow.vue";

export default {
  name: "StepPlanSettings",
  components: {PFRow},
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      form: {
        num_photo_included: null,
        extra_photo_block: null,
        extra_photo_price: null,
        num_video_included: null,
        extra_video_block: null,
        extra_video_price: null,
        num_participants_included: null,
        extra_participants_block: null,
        extra_participants_price: null,
        num_checkin_included: null,
        extra_checkin_block: null,
        extra_checkin_price: null,
        num_public_media_included: null,
        pica_fee_perc: null,
        pica_fee_perc_during_stay: null,
        additional_fee_perc: null,
        revenue_photographer_percent_after_pica: null,
        revenue_organizer_percent_after_pica: null,
        handling_fee: null,
        assigns_transaction_costs_to_pica: false,
      }
    }
  },
  emits: ['save'],
  mounted() {
    this.onReset()
  },
  computed: {},
  methods: {
    onReset() {
      this.form = {...this.data}
    },
    async onSave() {
      await this.emitPromised('save', this.form)
      this.onReset()
    },
  }
}
</script>