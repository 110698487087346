<template>
  <CModal :title="title" :show="true" @update:show="close" size="lg">
    <template v-if="!field.id">
      <CRow class="mb-3">
        <CCol>
                <label v-translate translate-context="form_configurator.label">Input type</label>
      <multiselect
          v-model="selectedType"
          @input="field.type = $event.value"
          :options="fieldOptions"
          label="label"
          track-by="value"
          placeholder="Pick a value"
      />
        </CCol>
      </CRow>

    </template>
    <FormTextInput :data.sync="field" v-if="[fieldTypes.TEXT,fieldTypes.PHONE,fieldTypes.EMAIL].includes(field.type)"
                   :advanced="advanced" :langs="langs"/>
    <FormSelectInput :data.sync="field" v-if="[fieldTypes.SELECT,fieldTypes.MULTY_SELECT].includes(field.type)"
                     :advanced="advanced" :langs="langs"/>
    <template #footer>
      <CButton @click="close" color="secondary" v-translate>Cancel</CButton>
      <CButton @click="save" color="primary" v-translate>Save</CButton>
    </template>
  </CModal>
</template>

<script>
import {fieldOptions, fieldTypes} from "@/domain/formConfigurator/fields";
import Multiselect from "vue-multiselect"
import FormTextInput from "@/domain/formConfigurator/Input/FormTextInput.vue";
import FormSelectInput from "@/domain/formConfigurator/Input/FormSelectInput.vue";

const emptyForm = {
  type: '',
  editable: true,
}

export default {
  name: "EditField",
  emits: ['save', 'cancel'],
  components: {FormSelectInput, FormTextInput, Multiselect},
  props: {
    data: {
      type: Object,
      default: null
    },
    advanced: {
      type: Boolean,
      required: true
    },
    langs: {
      type: Array,
      required: true
    }
  },
  computed: {
    fieldTypes() {
      return fieldTypes
    }, fieldOptions() {
      return fieldOptions
    }, title() {
      if (this.isNewField)
        return this.$pgettext('form_configurator.modal', 'Add new field')
      else
        return this.$pgettext('form_configurator.modal', 'Edit field')
    }
  },
  data() {
    return {
      selectedType: {},
      field: {},
      isNewField: false,
    }
  },
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.isNewField = this.data === null
      this.field = this.data ?? {...emptyForm}
      this.selectedType = this.fieldOptions.find((option) => {
        return option.value === this.field.type
      }) ?? {}
    },
    save() {
      this.$emit('save', this.field)
    },
    close() {
      this.$emit('cancel')
    }
  }
}
</script>