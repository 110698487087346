<template>
  <Summary
      :heading="setting.header"
      heading-icon="cipAnnouncement"
      :palette="setting.palette"
      :tooltip="setting.tooltip"
      :heading-value="heading"
      :rows="rows"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Filters from "@/domain/core/utils/filters";
import {Settings} from "@/domain/stats/settings";
import Summary from "@/domain/stats/components/Summary.vue";

export default {
  name: "EngagementSummary",
  components: {Summary},
  async mounted() {
    await this.fetchSummaryEngagement()
  },
  computed: {
    ...mapGetters('nStats', [
      'engagementSummary'
    ]),
    setting() {
      return Settings.summary.engagement
    },
    heading() {
      if (!this.engagementSummary) return
      return Filters.number(this.engagementSummary.total)
    },
    rows() {
      if (this.engagementSummary === null)
        return []
      return [
        {
          title: this.setting.social.title,
          tooltip: this.setting.social.tooltip,
          value: Filters.number(this.engagementSummary.social)
        },
        {
          title: this.setting.download.title,
          tooltip: this.setting.download.tooltip,
          value: Filters.number(this.engagementSummary.download),
        }
      ]
    },
  },
  methods: {
    ...mapActions('nStats', [
      'fetchSummaryEngagement'
    ]),
  }
}
</script>