<template>
  <PTransparentLoader :loading="loading" class="event-wizard">
    <CContainer class="p-0">
      <h1 class="main-header mb-1" v-translate translate-context="event_wizard">Banner ADV + Link</h1>
      <p>
        <translate translate-context="event_wizard.subtitle">Create a custom advertisement inside the participant's
          album
        </translate>
      </p>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Upload file')"
             :help-text="$pgettext('event_wizard.help', 'Your banner will appear at the bottom of the album')"
             separator
             label-class="big">
        <PUploader
            :params="uploadParams.banner"
            :get_intent="get_banner_upload_intent"
            :value.sync="v$.form.banner.$model"
            :help_text="$pgettext('uploader.help','Size: 600x600px - Allowed formats: .jpg .png')"
            @uploaded="newBannerUploaded"
        />
      </PFRow>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Action on banner')"
             :help-text="$pgettext('event_wizard.subtitle', 'Choose which behavior to activate when the user clicks on the banner')"
             separator
             label-class="big">
        <CRow form>
          <CCol lg="4">
             <PSelectKeyValue
              :options="target_types"
              :value.sync="target_type"
              @input="previewFieldUpdated"
          />
          </CCol>
          <CCol lg="8">
            <template v-if="target_type === 'link'">
            <CInput placeholder="https://example.getpica.com" v-model="v$.form.link.$model" ref="form-link"
                    @keyup="previewFieldUpdated">
              <template v-slot:invalid-feedback>
                <div class="invalid-feedback d-block" v-if="v$.form.link.$error">Link field has an error.</div>
              </template>
            </CInput>
          </template>
             <template v-if="target_type === 'email'">
            <CInput placeholder="info@example.com" v-model="v$.form.email.$model" ref="form-email"
                    @keyup="previewFieldUpdated" type="email">
              <template v-slot:invalid-feedback>
                <div class="invalid-feedback d-block" v-if="v$.form.email.$error">E-Mail field has an error.</div>
              </template>
            </CInput>
          </template>
          </CCol>
        </CRow>
      </PFRow>
    </CContainer>

    <div class="event-wizard__footer">
      <CRow>
        <CCol>
          <div class="d-flex justify-content-end">
            <div class="mr-2">
              <CButton color="secondary" block @click="onReset" ref="reset-button"
                       v-translate translate-context="event_wizard">
                Reset
              </CButton>
            </div>
            <div>
              <CButton color="primary" @click="onSave" block :disabled="v$.$invalid" ref="save-button"
                       v-translate translate-context="event_wizard">
                Save
              </CButton>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
  </PTransparentLoader>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {email, url} from "@vuelidate/validators";
import PUploader from "@/domain/core/components/PUploader.vue";
import {upload} from "@/domain/eventWizard/upload";
import {DELETE_KEY} from "@/domain/core/components/uploader";
import PTransparentLoader from "@/domain/core/components/PTransparentLoader.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import PFRow from "@/domain/core/components/PFRow.vue";
import {mapActions} from "vuex";

export default {
  name: "StepBannerADVAndLink",
  computed: {
    uploadParams() {
      return upload
    }
  },
  components: {PFRow, PSelectKeyValue, PTransparentLoader, PUploader},
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    get_banner_upload_intent: {
      type: Function,
      required: true,
    },
    get_upload_intent_public_url: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: {
        banner: null,
        link: null,
        email: null,
      },
      banner_url: null,
      target_type: null,
      target_types: [
        {name: this.$pgettext('event_wizard', 'Open link'), id: 'link'},
        {name: this.$pgettext('event_wizard', 'Send e-mail'), id: 'email'}
      ]
    }
  },
  emits: ['save', 'updatePreview'],
  setup() {
    return {v$: useVuelidate()}
  },
  mounted() {
    this.onReset()
  },
  validations() {
    return {
      form: {
        banner: {},
        link: {url},
        email: {email}
      }
    }
  },

  methods: {
    ...mapActions('eventWizard', ['fetchPublishValidation']),
    onReset() {
      this.form = {...this.data}
      this.banner_url = this.form.banner
      this.target_type = this.form.email && this.form.email.length ? 'email' : 'link'
      this.previewFieldUpdated()
    },

    async onSave() {
      if (!await this.v$.$validate()) return
      await this.emitPromised('save', {
        banner: this.form.banner,
        link: this.target_type === 'link' ? this.form.link : '',
        email: this.target_type === 'email' ? this.form.email : ''
      })
      await this.$nextTick()
      await this.fetchPublishValidation()
      this.onReset()
    },

    async newBannerUploaded() {
      await this.$nextTick()
      if (this.form.banner === DELETE_KEY)
        this.banner_url = ''
      else {
        this.banner_url = await this.get_upload_intent_public_url(this.form.banner)
      }
      this.previewFieldUpdated()
    },

    previewFieldUpdated() {
      this.$emit('updatePreview', {
        banner: this.banner_url,
        link: this.target_type === 'link' ? this.form.link : '',
        email: this.target_type === 'email' ? this.form.email : ''
      })
    },
  }
}
</script>

<style scoped>

</style>