export default class Palette {

    static palettes = {
        BLUE: ['#1867FF', '#0014CC'],
        PURPLE: ['#8800F2', '#3E0096'],
        FUCHSIA: ['#FC0090', '#A80068'],
        ORANGE: ['#FF7C24', '#C13200'],
    }

    static get blue() {
        let colors = []
        const mainColors = this.palettes.BLUE
        colors.push(mainColors)
        colors.push(this._getOthers(mainColors))
        return colors.flat()
    }

    static get fuchsia() {
        let colors = []
        const mainColors = this.palettes.FUCHSIA
        colors.push(mainColors)
        colors.push(this._getOthers(mainColors))
        return colors.flat()
    }

    static get orange() {
        let colors = []
        const mainColors = this.palettes.ORANGE
        colors.push(mainColors)
        colors.push(this._getOthers(mainColors))
        return colors.flat()
    }

    static get purple() {
        let colors = []
        const mainColors = this.palettes.PURPLE
        colors.push(mainColors)
        colors.push(this._getOthers(mainColors))
        return colors.flat()
    }

    static _getOthers(exclude) {
        let others = []
        Object.values(this.palettes).filter(x => x !== exclude).forEach(x => others.push(x))
        return others.flat()
    }
}