<template>
  <CRow class="align-items-center">
    <CCol col="8">
      <div class="p-3">
        <div class="d-flex align-items-center">
          <div class="p-uploader__media-wrapper compact mr-3" v-if="data.image">
            <img :src="data.image_url" alt="Option Image" class="p-uploader__img">
          </div>
          <div>
            <div>{{ data.name }}</div>
            <div class="text-help">{{ data.description }}</div>
          </div>
        </div>
      </div>
    </CCol>
    <CCol col="4">
      <div class="p-3" v-if="data.tag"><span class="badge badge-pill badge-outline badge-primary">{{ data.tag }}</span></div>
    </CCol>
  </CRow>
</template>

<script>


export default {
  name: "CheckinOption",
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
