import {translate} from 'vue-gettext';
const {gettext: $gettext, pgettext: $pgettext, gettextInterpolate: $gettextInterpolate} = translate;
import {helpers, minLength, required} from '@vuelidate/validators'


function inArray$1 (arr) {
  return value => !helpers.req(value) || arr.includes(value)
}

function inArray (arr) {
  return {
    $validator: inArray$1(arr),
    $message: _ref => {
      let {
        $params
      } = _ref;
      return `Allowed values are: ${$params.arr.join(', ')}`;
    },
    $params: {
      arr,
      type: 'inArray'
    }
  };
}

const htmlColor = (value) => !helpers.req(value) || CSS.supports('color',value)

const required$ = helpers.withMessage($pgettext('validation.error','Required field'), required)
export const minLength$ = (min) => helpers.withMessage(
  ({ $params}) => $gettextInterpolate($gettext(`This field should be at least %{min} characters long`), {min:$params.min}), minLength(min)
)
export {inArray, htmlColor, required$}