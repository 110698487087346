<template>
  <div>
    <CRow class="mb-4">
      <CCol>
        <label v-translate translate-context="form_configurator.consent.label">Field name ( not shown to the user -
          for export purpose )</label>
        <CInput v-model="key" @input="save()"/>
      </CCol>
    </CRow>
    <CRow class="mb-4">
      <CCol>
        <PTranslatableCkeditor v-model="text" :langs="langs" @input="save"/>
      </CCol>
    </CRow>
    <CRow class="mb-4">
      <CCol>
        <label v-translate translate-context="form_configurator.consent.label">Attachment (pdf)</label>
        <PUploader
            :params="uploadParams"
            :get_intent="get_consent_upload_intent"
            :value.sync="upload.intent"
            @uploaded="upload.file = $event;"
        />


      </CCol>
    </CRow>
    <CRow class="mb-4">
      <CCol>
        <CInput placeholder="Testo link" v-model="upload.text"/>
      </CCol>
    </CRow>
    <CRow class="mb-4">
      <CCol>
        <CButton @click="addLink" color="success" variant="outline">Add uploaded file to Text</CButton>
      </CCol>
    </CRow>

  </div>
</template>

<script>

import {consentTypes} from "@/domain/formConfigurator/fields";
import PUploader from "@/domain/core/components/PUploader.vue";
import PTranslatableCkeditor from "@/domain/core/components/PTranslatableCkeditor.vue";

export default {
  name: "FormConsentInput",
  components: {
    PTranslatableCkeditor,
    PUploader
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    get_consent_upload_intent: {
      type: Function,
      required: true
    },
    advanced: {
      type: Boolean,
      required: true
    },
    langs: {
      type: Array,
      required: true
    }
  },
  computed: {
    consentTypes() {
      return consentTypes
    },
    uploadParams() {
      return {
        allowed_extensions: ['pdf'],
        max_generic_size: 8000000,
      }
    }
  },
  data() {
    return {
      key: '',
      uploads: [],
      upload: {
        intent: '',
        file: '',
        text: '',
      },
      text: {}
    }
  },
  emits: ['update:data'],
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.key = this.data.key ?? ''
      this.text = this.data.text ?? {}
      this.uploads = this.data.uploads ?? []
    },
    save() {
      this.$emit('update:data', {
        ...this.data,
        key: this.key,
        uploads: this.uploads.filter((upload) => {
          return this.isUploadIntentInText(upload.intent)
        }),
        text: this.text,
      })
    },
    isUploadIntentInText(intent) {
      for (const lang of this.langs)
        if (this.text[lang] && this.text[lang].includes(`INTENT:${intent}`))
          return true
      return false
    },
    addLink() {
      const link = `<a href="{{INTENT:${this.upload.intent}}}">${this.upload.text}</a>`

      let texts = {...this.text} // if we update this.text[lang] it will not trigger the watcher
      for (const lang of this.langs) {
        if (!texts[lang]) texts[lang] = ''
        if (texts[lang].length && texts[lang].slice(-4) === '</p>')
          texts[lang] = texts[lang].slice(0, -4) + ' ' + link + '</p>'
        else
          texts[lang] += link
      }
      this.text = texts

      this.uploads.push({
        intent: this.upload.intent,
        file: this.upload.file,
      })

      this.upload.intent = ''
      this.upload.file = ''
      this.upload.text = ''

      this.save()
    }
  }
}
</script>