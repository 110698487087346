<template>
  <div class="position-relative emoji-input d-flex" v-c-clickaway="hidePicker">
    <div class="flex-grow-1 mr-2">
      <CInput type="text" v-model="text"
              ref="input"
              :maxlength="maxlength"
              @keyup="onKeyUp"
              @input="onInput($event)"
              @change="onChange($event)"
              @click="hidePicker"
              :is-valid="isValid"
              :addWrapperClasses="maxlength ? 'has-counter' : ''"
              :placeholder="placeholder">
        <template v-slot:description v-if="isValid">
          <div class="counter">{{ textLength }}/{{ maxlength }}</div>
        </template>
        <template v-slot:invalid-feedback>
            <div class="invalid-feedback d-block" v-for="error of errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </template>
      </CInput>
    </div>
    <div>
      <CButton class="input-group-text" @click="togglePicker">
        <CIcon class="m-0" name="cil-smile"/>
      </CButton>
    </div>

    <picker v-show="showPicker"
            :show-preview="false"
            :emoji-size="22"
            :per-line="9"
            ref="emojiPicker"
            :class="pickerPosition"
            @select="addEmoticon"/>
  </div>

</template>

<script>
import {Picker} from 'emoji-mart-vue'

export default {
  name: "PEmoticonInput",
  data() {
    return {
      pickerPosition: 'bottom',
      showPicker: false,
      text: null,
    }
  },
  components: {
    Picker
  },
  props: {
    value: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    maxlength: {
      type: Number,
      required: false,
    },
    isValid : {
      type: Boolean,
      required: false,
      default: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: ['update:value', 'input', 'keyup', 'change'],
  computed: {
    textLength() {
      return this.text ? this.text.length : 0
    },
  },
  async mounted() {
    this.init()
  },
  watch: {
    value: function () {
      this.init()
    },
  },
  methods: {
    init() {
      this.text = this.value || ''
      this.input = this.$refs.input.$el.querySelector('input')
    },
    async addEmoticon(emoticon) {
      const position = this.input.selectionStart

      const before = this.text.slice(0, position)
      const after = this.text.slice(position)

      this.text = before + emoticon.native + after

      this.$emit('keyup', this.text)
      this.$emit('input', this.text)
      this.$emit('update:value', this.text)

      this.hidePicker()
    },
    async togglePicker() {
      this.showPicker = !this.showPicker
      await this.$nextTick()
      if (this.showPicker) this.setPickerPosition();
    },
    setPickerPosition() {
      const inputRect = this.input.getBoundingClientRect()
      const pickerHeight = this.$refs.emojiPicker.$el.offsetHeight

      if (window.innerHeight - inputRect.bottom < pickerHeight) {
        this.pickerPosition = 'top'
      } else {
        this.pickerPosition = 'bottom'
      }
    },
    hidePicker() {
      this.showPicker = false
    },
    onKeyUp(e) {
      this.text = e.target.value
      this.$emit('keyup', this.text, e)
    },
    onInput(e) {
      this.text = e
      this.$emit('input', this.text, e)
      this.$emit('update:value', this.text, e)
    },
    onChange(e) {
      this.text = e
      this.$emit('change', this.text, e)
      this.$emit('update:value', this.text, e)
    },
  }
}
</script>

