<template>
  <div v-if="loaded">
    <CRow v-if="advanced">
      <CCol>
        <label v-translate translate-context="form_configurator.text.label">Field Key</label>
        <CInput v-model="key" @input="save" :placeholder="keyPlaceholder"/>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <label v-translate translate-context="form_configurator.select.label">Field name</label>
        <PTranslatableTextInput v-model="name" @input="save" :langs="langs"/>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <draggable v-model="params.options" @end="updateOptionSorting" handle=".handle" ghost-class="ghost">
          <transition-group type="transition" :name="'flip-list'">
            <div v-for="option in params.options" :key="option.value"
                 class="form-generator__field form-generator__field--modal">
              <div class="flex-grow-1 mr-4">
                <CRow>
                  <CCol sm="6">
                    <div class="p-3 font-weight-bold">{{ option.value }}</div>
                  </CCol>
                  <CCol sm="6">
                    <div class="p-3">{{ option.label['it'] }}</div>
                  </CCol>
                </CRow>
              </div>
              <div class="handle p-3">
                <CIcon size="lg" name="cip-hamburger"/>
              </div>
              <div class="mr-3">
                <CButtonClose  class="list-item-closer"  @click.prevent="deleteOption(option.value)">
                  <CIcon name="cipClose" size="custom-size" width="15" />
                </CButtonClose>
              </div>

            </div>
          </transition-group>
        </draggable>

      </CCol>
    </CRow>
    <CRow class="mb-4">
      <CCol md="6" v-if="advanced">
        <CInput v-model="newOption.value" :label="$pgettext('form_configurator.select','Value')"
                :placeholder="newOption.valuePlaceholder"/>
      </CCol>
      <CCol md="6">
        <PTranslatableTextInput
            v-model="newOption.label"
            :placeholder="$pgettext('form_configurator.select','Label')"
            :label="$pgettext('form_configurator.select','Label')"
            :langs="langs"
            @input="updatePlaceholder"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CButton color="success" variant="outline" @click="addOption">
          Add option
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import PTranslatableTextInput from "@/domain/core/components/PTranslatableTextInput.vue";
import {generateKey} from "@/domain/formConfigurator/fields";
import draggable from 'vuedraggable'

export default {
  name: "FormSelectInput",
  components: {
    draggable,
    PTranslatableTextInput,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    advanced: {
      type: Boolean,
      required: true
    },
    langs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      name: '',
      key: '',
      keyPlaceholder: '',
      params: {
        options: [],
      },
      newOption: {
        value: '',
        valuePlaceholder: '',
        label: {}
      }
    }
  },
  emits: ['update:data'],
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.key = this.data.key ?? ''
      this.name = this.data.name ?? {}
      this.params = this.data.params ?? {options: []}
      this.newOption.label = {}
      this.newOption.valuePlaceholder = ''
      this.newOption.value = ''
      this.loaded = true
    },
    save() {
      this.keyPlaceholder = generateKey(this.name)
      this.$emit('update:data', {
        ...this.data,
        name: this.name,
        params: this.params
      })
    },
    addOption() {
      if (this.newOption.label === '') return
      if (this.newOption.value === '') this.newOption.value = generateKey(this.newOption.label)

      this.params.options.push({
        value: this.newOption.value,
        label: this.newOption.label
      })
      this.newOption.value = ''
      this.newOption.valuePlaceholder = ''
      this.newOption.label = {}
      this.save()
    },
    deleteOption(value) {
      this.params.options = this.params.options.filter(option => option.value !== value)
      this.save()
    },
    updatePlaceholder() {
      this.newOption.valuePlaceholder = generateKey(this.newOption.label)
    }, updateOptionSorting() {
      this.params.options = this.params.options.map((input, index) => {
        input.order = index + 1
        return input
      })
    }
  }
}
</script>