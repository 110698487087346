<template>
  <PTransparentLoader class="event-wizard event-wizard__step_logo" :loading="loading || set_loading">
    <CContainer class="p-0">
      <h1 class="main-header mb-1" v-translate translate-context="event_wizard">Photo branding</h1>
      <p>
        <translate translate-context="event_wizard.label">Add a logo or banner that wil be applied at the bottom of
          every event photo
        </translate>
      </p>
      <PAlert v-if="event_started" color="warning"
          icon="cipAlertTriangle"
          class="mb-3"
          :text="$pgettext('event_wizard','The event already started, someone could have already downloaded some photo with the old settings.')"></PAlert>
      <CRow>
        <CCol lg="6">
          <PSelectKeyValue :options="form.logo_sets"
                           label="id" :value.sync="activeSetId"
                           image="image"
                           @input="onChangeLogoSet"
                           v-if="form.logo_sets.length > 1"/>
        </CCol>
      </CRow>
    </CContainer>
    <LogoSetEditor
        v-if="logoSet && !set_loading"
        :data="logoSet"
        :get_upload_intent="get_logo_upload_intent"
        :get_upload_intent_public_url="get_upload_intent_public_url"
        :available_tags="logoOnPhotoTags"
        :advanced="advanced"
        @save="saveSet($event)"
        @updatePreview="$emit('updatePreview', $event)"
    />

    <div class="event-wizard__footer aux" v-if="advanced">
      <CRow>
        <CCol>
          <div class="d-flex justify-content-start">
            <div class="mr-2">
              <CButton block @click="deleteSet" color="danger" v-if="canDeleteSet" v-translate>Delete Set
              </CButton>
            </div>
            <div>
              <CButton block @click="addSet" color="success" variant="outline"><CIcon name="cipPlus" size="custom-size" style="margin-top: -3px"
                    :height="12"/> <translate>Add Set</translate></CButton>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
  </PTransparentLoader>
</template>

<script>
import {cloneDeep} from "lodash";
import LogoSetEditor from "@/domain/logoSet/LogoSetEditor.vue";
import {mapActions, mapGetters} from "vuex";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import PTransparentLoader from "@/domain/core/components/PTransparentLoader.vue";
import PAlert from "@/components/PAlert.vue";

export default {
  name: "StepCustomizedLogoOnPhoto",
  components: {PAlert, PTransparentLoader, PSelectKeyValue, LogoSetEditor},
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    get_logo_upload_intent: {
      type: Function,
      required: true
    },
    event_started: {
      type: Boolean,
      default: false
    },
    get_upload_intent_public_url: {
      type: Function,
      required: true,
    },
    advanced: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: {
        logo_sets: []
      },
      empty: {
        isNew: true,
        id: null,
        type: null,
        tags: [],
        logos: [],
      },
      set_loading: false,
      logoSet: null,
      activeSetId: null,
    }
  },
  computed: {
    ...mapGetters('eventWizard', ['logoOnPhotoTags']),
    canDeleteSet() {
      return this.form.logo_sets.length > 1 && this.advanced && this.logoSet && this.logoSet.id
    },
  },
  emits: ['delete', 'save', 'updatePreview'],
  async mounted() {
    await this.fetchLogoOnPhotoTags()
    this.onReset()
  },
  methods: {
    ...mapActions('logoSet', [
      'fetchLogoSet',
      'updateLogoSet',
      'deleteLogoSet',
      'createLogoSet'
    ]),
    ...mapActions('eventWizard', ['fetchLogoOnPhotoTags']),
    onReset() {
      this.form = cloneDeep(this.data)
      if (!this.form.logo_sets.length) this.addSet()
      else {
        this.form.logo_sets.sort((a, b) => a.id - b.id)
        if (this.activeSetId === null) {
          this.activeSetId = this.form.logo_sets[0].id
          this.onChangeLogoSet()
        }
      }
    },
    addSet() {
      this.form.logo_sets.push({...this.empty})
      this.logoSet = {...this.empty}
    },
    async deleteSet() {
      if (!confirm('Are you sure you want to delete this logo?')) return false
      if (this.activeSetId > 0) await this.deleteLogoSet(this.activeSetId)
      this.activeSetId = null
      this.logoSet = null
      await this.emitPromised('save', [])
      if (!this.form.logo_sets.length) this.addSet()
    },
    async saveSet(data) {
      if (data.type == null) {
        if (data.id) return this.deleteSet()
        return
      }
      if (data.id)
        data = await this.updateLogoSet(data)
      else
        data = await this.createLogoSet(data)
      this.activeSetId = data.id
      this.logoSet = await this.fetchLogoSet(this.activeSetId)
      this.$emit('save', [])
    },
    async onChangeLogoSet() {
      if (this.activeSetId === null) return

      this.set_loading = true
      this.logoSet = await this.fetchLogoSet(this.activeSetId)
      this.set_loading = false
    }
  }
}
</script>

<style scoped>

</style>