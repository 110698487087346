<template>
  <div>
    <div class="py-4">
      <div class="mb-3">
        <ckeditor :editor="editor" v-model="noteText"
                  :config="editorConfig"></ckeditor>
      </div>
      <div class="text-right">
        <CButton @click="onSaveNote" color="primary" :disabled="noteText.length === 0">
          <translate>Save</translate>
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue2";

export default {
  name: 'EventNoteForm',
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  emits: ['save'],
  watch: {
    text: function () {
      this.noteText = this.text
    },
  },
  mounted() {
    this.noteText = this.text
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
        link: {
          defaultProtocol: 'https://',
          decorators: {
            openInNewTab: {
              defaultValue: true,
              mode: 'manual',
              label: this.$pgettext('ckeditor', 'Open in a new tab'),
              attributes: {
                target: '_blank',
              }
            }
          }
        }
      },
      noteText: '',
    }
  },
  methods: {
    async onSaveNote() {
      await this.$emit('save', this.noteText)
    },
  }
}
</script>
