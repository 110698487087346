<template>
  <PTransparentLoader :loading="loading" class="event-wizard">
    <CContainer class="p-0">
      <h1 class="main-header mb-1" v-translate translate-context="event_wizard">PopUp</h1>
      <p>
        <translate translate-context="event_wizard.subtitle">
          Use the checkin popup to inform the user
        </translate>
      </p>

      <CRow>
        <CCol col="5">
          <h5 class="mb-1" v-translate translate-context="event_wizard.label">Welcome Popup</h5>
          <p v-translate translate-context="event_wizard.subtitle">
            This popup will be shown when the user enter for the first time in the event</p>
        </CCol>
      </CRow>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Title')"
             label-class="big">
        <PTranslatableTextInput
            v-model="v$.form.welcome_popup_title.$model"
            @input="previewFieldUpdated"
            :placeholder="$pgettext('event_wizard.placeholder','Welcome Popup Title')"
        />
      </PFRow>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Message')" separator
             label-class="big">
        <PTranslatableCkeditor
            v-model="v$.form.welcome_popup_text.$model"
            @input="previewFieldUpdated"
            :placeholder="$pgettext('event_wizard.placeholder','Welcome Popup Text')"
        />
      </PFRow>

      <CRow class="">
        <CCol col="12">
          <h5 class="mb-1" v-translate translate-context="event_wizard.label">Fixed Popup</h5>
          <p v-translate translate-context="event_wizard.subtitle">
            This text will always be visible</p>
        </CCol>
      </CRow>

      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Title')" separator
             label-class="big">
        <PTranslatableTextInput
            v-model="v$.form.fixed_popup_title.$model"
            @input="previewFieldUpdated"
            :placeholder="$pgettext('event_wizard.placeholder','Fixed Popup Title')"
        />
      </PFRow>
      <PFRow size="5x7" :label="$pgettext('event_wizard.label', 'Message')" separator
             label-class="big">
        <PTranslatableCkeditor
            v-model="v$.form.fixed_popup_text.$model"
            @input="previewFieldUpdated"
            :placeholder="$pgettext('event_wizard.placeholder','Fixed Popup')"
        />
      </PFRow>
    </CContainer>

    <div class="event-wizard__footer">
      <CRow>
        <CCol>
          <div class="d-flex justify-content-end">
            <div class="mr-2">
              <CButton color="secondary" block @click="onReset" ref="reset-button"
                       v-translate translate-context="event_wizard">
                Reset
              </CButton>
            </div>
            <div>
              <CButton color="primary" @click="onSave" block :disabled="v$.$invalid" ref="save-button"
                       v-translate translate-context="event_wizard">
                Save
              </CButton>
            </div>

          </div>
        </CCol>
      </CRow>
    </div>

  </PTransparentLoader>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import PTranslatableCkeditor from "@/domain/core/components/PTranslatableCkeditor.vue";
import PTransparentLoader from "@/domain/core/components/PTransparentLoader.vue";
import PTranslatableTextInput from "@/domain/core/components/PTranslatableTextInput.vue";
import PFRow from "@/domain/core/components/PFRow.vue";

export default {
  name: "StepPopup",
  components: {PFRow, PTranslatableTextInput, PTranslatableCkeditor, PTransparentLoader},
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      form: {
        welcome_popup_text: {},
        welcome_popup_title: {},
        fixed_popup_text: {},
        fixed_popup_title: {}
      },
    }
  },
  emits: ['save', 'updatePreview'],
  setup() {
    return {v$: useVuelidate()}
  },
  mounted() {
    this.onReset()
  },
  validations() {
    return {
      form: {
        welcome_popup_text: {},
        welcome_popup_title: {},
        fixed_popup_text: {},
        fixed_popup_title: {},
      }
    }
  },

  methods: {
    onReset() {
      this.form = {...this.data}
      this.previewFieldUpdated()
    },

    async onSave() {
      if (!await this.v$.$validate()) return
      await this.emitPromised('save', this.form)
      this.onReset()
    },

    async previewFieldUpdated() {
      await this.$nextTick()
      this.$emit('updatePreview', {
        welcome_popup_text: this.form.welcome_popup_text,
        welcome_popup_title: this.form.welcome_popup_title,
        fixed_popup_text: this.form.fixed_popup_text,
        fixed_popup_title: this.form.fixed_popup_title
      })
    },
  }
}
</script>

<style scoped>

</style>