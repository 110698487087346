<template>
  <div class="text-center my-4" :class="extraClass" v-if="loading">
    <CSpinner color="primary" :size="size"/>
    <h6 v-if="showLoadingText" class="mt-2" v-translate>Loading...</h6>
  </div>
</template>

<script>
export default {
  name: "ViewLoadingMessage",

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    showLoadingText: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'lg'
    },
    extraClass: {
      type: String,
      required: false
    }
  },
}
</script>
