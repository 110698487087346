<template>
  <PTransparentLoader :loading="loading" class="event-wizard">
    <div class="d-flex align-items-center">
      <h1 class="main-header mb-1" v-translate translate-context="event_wizard.overview">Overview</h1>
      <CButton @click="startTour" class="ml-2 p-0" size="sm" style="color: #a9a8c2" v-if="hasPerm('feature.overview_tour')">
        <CIcon name="cipCircleHelp" style="vertical-align: baseline"/>
      </CButton>
    </div>

    <p v-translate translate-context="event_wizard.overview">Event overview: key data, actions and notes</p>
    <template v-if="isDraft">
      Publish your event to see the overview
    </template>
    <template v-else>
      <Stats/>
      <CRow>
        <GalleriesUpload/>
        <MediaKit :media-kit="data"/>
        <QRCodeGenerator ref="qrCodeGenerator"
                         :value="qrCode"
                         :direct="data.qr_code"
                         :short="data.short_qr_code"
                         :file-name="`QRCode-${picaServiceId}`"/>
        <GalleriesPricelist/>

      </CRow>
    </template>
    <CRow>
      <CCol xl="6" v-if="hasPerm('dashboard.view_feature_operation_log')">
        <EventNote/>
      </CCol>
    </CRow>
    <PTour ref="tourOverview" :name="tourName" :steps="overviewSteps"/>
  </PTransparentLoader>
</template>

<script>

import PTransparentLoader from "@/domain/core/components/PTransparentLoader.vue";
import EventNote from "@/domain/eventWizard/components/overview/EventNote.vue";
import {mapGetters} from "vuex";
import MediaKit from "@/domain/eventWizard/components/overview/MediaKit.vue";
import Stats from "@/domain/eventWizard/components/overview/Stats.vue";
import PTour from "@/domain/tours/components/PTour.vue";
import GalleriesUpload from "@/domain/eventWizard/components/overview/GalleriesUpload.vue";
import QRCodeGenerator from "@/domain/core/components/qrcode/QRCodeGenerator.vue";
import {overviewSteps} from "@/domain/tours/tours";
import GalleriesPricelist from "@/domain/eventWizard/components/overview/GalleriesPricelist.vue";

export default {
  name: "StepOverview",
  components: {
    GalleriesPricelist,
    QRCodeGenerator, GalleriesUpload, PTour, Stats, MediaKit, EventNote, PTransparentLoader
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: [Object],
      required: true
    },
  },
  computed: {
    ...mapGetters('event', [
      'isDraft',
      'picaServiceId',
    ]),
    ...mapGetters('tours', [
      'isTourViewed'
    ]),
    qrCode() {
      if (this.data.short_qr_code) return this.data.short_qr_code
      return this.data.qr_code
    },
    overviewSteps() {
      return overviewSteps
    },
    tourName() {
      return `event-overview-${this.picaServiceId}`
    }
  },
  async mounted() {
    await this.delay(1000)
    if (!this.isTourViewed(this.tourName) && this.hasPerm('feature.overview_tour'))
      this.startTour()
  },
  methods: {
    startTour() {
      this.$refs.tourOverview.start('0', 1000)
    }
  }
}
</script>
